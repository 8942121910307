import React, { useState } from "react";
import { AppBarModalTemplate1 } from "./Template1AppBarsModal";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

import { Loading } from "./Template1Loadings";

export default function Template1Modal({
  open,
  setOpen,
  content,
  title,
  callback = () => {},
  showBthSuccess = false,
  disabledBtnSuccess = false,
}) {
  const [wait, setWait] = useState(false);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBarModalTemplate1
        title={title}
        title_accion="Cerrar"
        accion={() => setOpen(false)}
      />
      <DialogContent className="scroll">
        {wait ? <Loading /> : content}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Cerrar
        </Button>
        {showBthSuccess && (
          <Button
            disabled={disabledBtnSuccess}
            variant="contained"
            color="primary"
            style={{ backgroundColor: disabledBtnSuccess ? "grey" : "#3498db" }}
            onClick={() => callback(setWait)}
          >
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
