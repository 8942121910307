import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import InputFile from "../general/InputFile";
import { BtnForm } from "../general/ButtonAccion";
import { SUBSCRIPTIONS } from "../general/helperGeneric";
import { Toast } from "../../global/js/funciones";
import Information from "../general/Information";

class CreateSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      names: { value: "", error: false },
      description: "",
      template: "",
      periodicity: { value: "", error: false },
      id_scen: { value: "", error: false },
      reference: { value: "", error: false },
      product: { value: "", error: false },
      code: { value: "", error: false },
      valuea: { value: "", error: false },
      valueb: { value: "", error: false },
      archivoGeneric: "",
    };
    this.fileInput = React.createRef();
  }

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: { value: value },
    });
  };

  onChangeSimple = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  conError(valor) {
    return valor ? true : false;
  }

  onSubmit = (e) => {
    let {
      names,
      valuea,
      valueb,
      product,
      reference,
      template,
      periodicity,
      id_scen,
    } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!names.value) {
      names.error = obligatorio;
      error = true;
      this.setState({ names: { error: true } });
    }
    if (!valuea.value) {
      valuea.error = obligatorio;
      error = true;
      this.setState({ valuea: { error: true } });
    }
    if (!product.value) {
      product.error = obligatorio;
      error = true;
      this.setState({ product: { error: true } });
    }
    if (!reference.value) {
      reference.error = obligatorio;
      error = true;
      this.setState({ reference: { error: true } });
    }
    if (!periodicity.value) {
      periodicity.error = obligatorio;
      error = true;
      this.setState({ periodicity: { error: true } });
    }
    if (!valueb.value) {
      valueb.error = obligatorio;
      error = true;
      this.setState({ valueb: { error: true } });
    }
    if (!id_scen.value) {
      id_scen.error = obligatorio;
      error = true;
      this.setState({ id_scen: { error: true } });
    }
    if (!template) {
      error = true;
    }
    if (!error) {
      this.sendData();
    } else
      Toast.fire({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
      });
  };
  sendData() {
    let {
      names,
      description,
      valuea,
      valueb,
      archivoGeneric,
      reference,
      product,
      template,
      periodicity,
      id_scen,
    } = this.state;
    let archivoSend = archivoGeneric
      ? document.getElementById("archivoGeneric").files[0]
      : "";
    const data = new FormData();
    data.append("name", names.value);
    data.append("periodicity", periodicity.value);
    data.append("description", description);
    data.append("valuea", valuea.value);
    data.append("valueb", valueb.value);
    data.append("generic", SUBSCRIPTIONS);
    data.append("files", archivoSend);
    data.append("reference", reference.value);
    data.append("product", product.value);
    data.append("id_scen", id_scen.value);
    data.append("template", template);

    this.props.saveValueGeneric(data, () => this.setState({ charging: false }));
    this.reset();
  }

  reset() {
    this.setState({
      names: { value: "", error: false },
      description: "",
      template: "",
      reference: { value: "", error: false },
      periodicity: { value: "", error: false },
      id_scen: { value: "", error: false },
      product: { value: "", error: false },
      code: { value: "", error: false },
      valuea: { value: "", error: false },
      valueb: { value: "", error: false },
      archivoGeneric: "",
    });
  }

  view() {
    let {
      names,
      description,
      charging,
      archivoGeneric,
      valuea,
      valueb,
      reference,
      product,
      template,
      periodicity,
      id_scen,
    } = this.state;
    let { templates } = this.props;
    return !charging ? (
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <TextField
            value={names.value}
            required
            type="text"
            id="names"
            name="names"
            label="Nombre"
            error={this.conError(names.error)}
            helperText={names.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={description}
            type="text"
            id="description"
            name="description"
            label="Descripción"
            fullWidth
            onChange={this.onChangeSimple}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={product.value}
            required
            type="text"
            id="product"
            name="product"
            label="Producto"
            error={this.conError(product.error)}
            helperText={product.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={reference.value}
            required
            type="text"
            id="reference"
            name="reference"
            label="Referencia"
            error={this.conError(reference.error)}
            helperText={reference.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={periodicity.value}
            required
            type="number"
            id="periodicity"
            name="periodicity"
            label="Periodicidad"
            error={this.conError(periodicity.error)}
            helperText={periodicity.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            value={id_scen.value}
            required
            type="text"
            id="id_scen"
            name="id_scen"
            label="Id Scen"
            error={this.conError(id_scen.error)}
            helperText={id_scen.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl className="form-control" required fullWidth>
            <InputLabel>Plantilla</InputLabel>
            <Select
              id="template"
              value={template}
              onChange={this.onChangeSimple}
              name="template"
            >
              {templates.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={valuea.value}
            required
            type="text"
            id="valuea"
            name="valuea"
            label="Terminos y Condiciones"
            error={this.conError(valuea.error)}
            helperText={valuea.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={valueb.value}
            required
            type="text"
            id="valueb"
            name="valueb"
            label="Política de datos"
            error={this.conError(valueb.error)}
            helperText={valueb.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="oculto"
            ref={this.fileInput}
            type="file"
            id="archivoGeneric"
            name="archivoGeneric"
            label="Archivo"
            fullWidth
            autoComplete="billing address-line1"
            onChange={this.onChangeSimple}
          />
          <Grid item xs={12}>
            <InputFile
              label="Archivo"
              id="archivoGeneric"
              value={archivoGeneric}
            />
          </Grid>
        </Grid>
        <Information
          title="Importante"
          description="Se recomienda que las imágenes cargadas tengan un ancho y largo  entre los 800px y los 1500px."
          type="info"
        />
      </Grid>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let { showModalAddSubscriptions, modalAddSubscriptions } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalAddSubscriptions}
          onClose={() => showModalAddSubscriptions(false)}
        >
          <AppBarModal
            title={" ¡ Agregar Suscripción ! "}
            showMessageModal={() => showModalAddSubscriptions(false)}
            title_accion="Cerrar"
            accion={() => showModalAddSubscriptions(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddSubscriptions(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
CreateSubscriptions.propTypes = {
  modalAddSubscriptions: PropTypes.bool.isRequired,
};

export default CreateSubscriptions;
