import React from "react";
import { Box, Tab } from "@material-ui/core";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import { getWindowDimensions } from "../../global/js/funciones";

const MenuBotton = ({ value }) => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      console.log(windowDimensions);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        zIndex: 1,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        backgroundColor: "white",
        margin: "0 auto",
      }}
    >
      <Tabs
        value={value}
        onChange={() => {}}
        variant={windowDimensions.width < 800 ? "scrollable" : "fullWidth"}
        scrollButtons={true}
        centered={false}
        aria-label="scrollable prevent tabs example"
        textColor="primary"
        className="menu-admin"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        <Tab
          label="Suscripciones"
          component={Link}
          to={"/admin/suscripciones"}
          icon={<AddToPhotosIcon />}
        />
        <Tab
          label="Cursos"
          component={Link}
          to={"/admin/cursos"}
          icon={<AssignmentIcon />}
        />
        <Tab
          label="Modulos"
          component={Link}
          to={"/admin/modulos"}
          icon={<MenuBookIcon />}
        />
        <Tab
          label="Categorías"
          component={Link}
          to={"/admin/categorias"}
          icon={<LibraryBooksIcon />}
        />
        <Tab
          label="Exámenes"
          component={Link}
          to={"/admin/examenes"}
          icon={<HelpCenterIcon />}
        />
        <Tab
          label="Usuarios"
          component={Link}
          to={"/admin/usuarios"}
          icon={<GroupIcon />}
        />
      </Tabs>
    </Box>
  );
};
export default MenuBotton;
