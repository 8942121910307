import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import {
  getValidate,
  validateInputs,
  consulta,
  viewError,
  Toast,
} from "../../../global/js/funciones";
import { StylesLogin } from "../../../global/css/components/StylesApp";
import loginImage from "../../../global/images/LoginAdmin.svg";
import logoImage from "../../../global/images/logo.png";

export default function Login() {
  const classes = StylesLogin();
  const [charging, setCharging] = useState(false);
  const [verifyToken, setVerifyToken] = useState(true);
  const [validate, setValidate] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    isAuth();
  }, []);

  const onSubmit = async () => {
    let validate = await validateInputs([
      { value: email, name: "email", err: { empty: true } },
      { value: password, name: "password", err: { empty: true } },
    ]);
    if (validate.length == 0) {
      setValidate([]);
      setCharging(true);
      consulta(
        `api/v1.0/users/token`,
        { email, password },
        "post",
        (error, estado, resp) => {
          if (!error) {
            if (estado === 200) {
              Toast.fire({ icon: "success", title: "Bienvenido" });
              localStorage.setItem("access_token", resp.access);
              localStorage.setItem("refresh_token", resp.refresh);
              localStorage.setItem("email", resp.user.email);
              window.location.href = "./suscripciones";
              Toast.fire({ icon: "success", title: "Bienvenido..." });
            } else {
              console.log(resp.title ? resp.title : viewError(resp));
              Toast.fire({
                icon: "info",
                title: "Correo o contraseña incorrectos.",
              });
            }
          } else {
            Toast.fire({
              icon: "info",
              title: "Correo o contraseña incorrectos.",
            });
          }

          setCharging(false);
        },
        false
      );
    } else {
      setValidate([]);
      setValidate(validate);
    }
  };
  const isAuth = async () => {
    let token = localStorage.getItem("access_token");
    setVerifyToken(true);
    consulta(
      `api/v1.0/users/token/verify`,
      { token },
      "post",
      (error, estado, resp) => {
        if (!error) {
          if (estado === 200) {
            Toast.fire({ icon: "success", title: "Bienvenido..." });
            window.location.href = "./suscripciones";
          }
        }
        if (estado !== 200) setVerifyToken(false);
      },
      false
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      className={classes.content}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={7}
        lg={7}
        xl={7}
        className={classes.boxLoginImage}
      >
        <img src={loginImage} alt="Imagen Login" className={classes.image} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        lg={4}
        xl={4}
        className={classes.boxLogin}
      >
        <div className={classes.boxLoginContent}>
          <img
            src={logoImage}
            alt="Imagen Logo"
            height="100px"
            width="100px"
            className={classes.logoDesktop}
          />
          <img
            src={loginImage}
            alt="Imagen Login app"
            height="200px"
            width="200px"
            className={classes.logoAppHeader}
          />
          {verifyToken ? (
            <>
              <Typography color="textSecondary">
                Hola, estamos verificando algunas cosas. Espera por favor.
              </Typography>
              <Box>
                <CircularProgress size={20} />
              </Box>
            </>
          ) : (
            <>
              <TextField
                value={email}
                required
                type="email"
                id="email"
                name="email"
                label="Correo"
                error={!!getValidate("email", validate)}
                helperText={getValidate("email", validate)}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                className={classes.boxLoginInput}
              />

              <TextField
                value={password}
                required
                type="password"
                id="password"
                name="password"
                label="Contraseña"
                error={!!getValidate("password", validate)}
                helperText={getValidate("password", validate)}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                className={classes.boxLoginInput}
              />

              <Button
                variant="contained"
                size="large"
                disabled={charging}
                fullWidth
                color="primary"
                className={classes.boxLoginInput}
                onClick={onSubmit}
              >
                {charging && (
                  <Box className={classes.loading}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                Ingresar
              </Button>
            </>
          )}

          <img
            src={logoImage}
            alt="Imagen Logo App"
            height="50px"
            width="50px"
            className={classes.logoApp}
          />
        </div>
      </Grid>
    </Grid>
  );
}
