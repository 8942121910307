import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Checkbox,
  DialogActions,
  Card,
  TextField,
  CardContent,
  Typography,
} from "@material-ui/core";
import { consulta } from "../../global/js/funciones";
import moment from "moment";
import { BtnForm } from "../general/ButtonAccion";
import { MODULES } from "../general/helperGeneric";

function Weight(component_props) {
  let {
    showModalAddCoursesModulesWeight,
    modalAddCoursesModulesWeight,
    weight,
    onChange,
    saveWeight,
  } = component_props;
  return (
    <Dialog
      open={modalAddCoursesModulesWeight}
      maxWidth="xs"
      fullWidth={true}
      onClose={showModalAddCoursesModulesWeight.bind(this, false)}
    >
      <AppBarModal
        title="¡ Agregar Peso !"
        showMessageModal={showModalAddCoursesModulesWeight}
        title_accion=""
      />
      <DialogContent style={{ padding: 0 }} className="scroll">
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Agrega un peso al modulo, recuerda que la suma de todos los
              modules debe ser 100
            </Typography>
            <Grid item xs={12}>
              <TextField
                value={weight}
                required
                type="number"
                id="weight"
                name="weight"
                label="Peso %"
                fullWidth
                onChange={onChange}
              />
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm text="Aceptar" callback={() => saveWeight()} />
      </DialogActions>
    </Dialog>
  );
}

class CourseModulesAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      modules: [],
      checked: [],
      infoChecked: [],
      weight: "",
    };
  }

  async componentDidUpdate({ modalAddCoursesModules }) {
    let { modalAddCoursesModules: modalAddCoursesModulesNew } = this.props;
    if (modalAddCoursesModulesNew !== modalAddCoursesModules) {
      this.data();
    }
  }

  async data() {
    this.setState({ charging: true });
    let modules = await this.listModules();
    this.setState({ modules, charging: false });
  }

  async listModules() {
    let f = MODULES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  reset() {
    this.setState({ checked: [], infoChecked: [] });
  }

  onSubmit = (e) => {
    let { infoChecked } = this.state;
    let { process, showModalAddCoursesModules, weightTotal } = this.props;
    let data = [];
    let info;
    infoChecked.map((dataIn) =>
      data.push({
        principal: dataIn.id,
        secondary: process.id,
        weight: dataIn.weight,
        weightTotal: weightTotal,
      })
    );
    info = { data };
    this.props.addPermissionGeneric(info, () =>
      this.setState({ charging: false })
    );
    showModalAddCoursesModules(false);
    this.reset();
  };

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  saveWeight = (e) => {
    let { weight, checked, infoChecked } = this.state;
    let { showModalAddCoursesModulesWeight, updateMessage } = this.props;
    if (weight !== "") {
      let lastCheck = checked[checked.length - 1];
      infoChecked.push({ id: lastCheck, weight: weight });
      showModalAddCoursesModulesWeight(false);
    } else
      updateMessage({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
        showMessage: true,
        time: 6000,
      });
    this.setState({ weight: "" });
  };

  view() {
    let { showModalAddCoursesModulesWeight, modalAddCoursesModulesWeight } =
      this.props;
    let { charging, modules, checked, weight, infoChecked } = this.state;
    const handleChange = (data) => async () => {
      const currentIndex = checked.indexOf(data.id);
      let newChecked = [...checked];
      if (currentIndex === -1) {
        showModalAddCoursesModulesWeight(true);
        newChecked.push(data.id);
      } else {
        newChecked.splice(currentIndex, 1);
        infoChecked.splice(currentIndex, 1);
      }
      this.setState({ checked: newChecked });
    };

    const actions = (data) => {
      const detail = (
        <Checkbox
          edge="end"
          checked={checked.indexOf(data.id) !== -1}
          onChange={handleChange(data)}
        />
      );
      return detail;
    };

    return (
      <>
        {!charging ? (
          <>
            <ListarDatos
              id="tbl_list_generic_modules"
              datos={modules}
              acciones={(row) => actions(row)}
              fila_principal={({ name }) => name.toUpperCase()}
              filas={[
                {
                  showMessage: ({ date_add }) =>
                    moment(date_add).format("YYYY-MM-DD, h:mm a"),
                  id: "date_add",
                  enLista: true,
                },
              ]}
            />
          </>
        ) : (
          <Grid container direction={"row"} justifyContent={"center"}>
            <Grid item md={2}>
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={50} />
              </Box>
            </Grid>
          </Grid>
        )}
        <Weight
          {...{
            showModalAddCoursesModulesWeight,
            modalAddCoursesModulesWeight,
            weight,
            saveWeight: this.saveWeight,
            onChange: this.onChange,
          }}
        />
      </>
    );
  }

  render() {
    let { showModalAddCoursesModules, modalAddCoursesModules } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={modalAddCoursesModules}
          onClose={() => showModalAddCoursesModules(false)}
        >
          <AppBarModal
            title={" ¡Agregar Modulos! "}
            showMessageModal={() => showModalAddCoursesModules(false)}
            title_accion="Cerrar"
            accion={() => showModalAddCoursesModules(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddCoursesModules(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
CourseModulesAdd.propTypes = {
  modalAddCoursesModules: PropTypes.bool.isRequired,
  modalAddCoursesModulesWeight: PropTypes.bool.isRequired,
};

export default CourseModulesAdd;
