import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export const drawerWidth = 300;

export const stylesTemplate1 = makeStyles((theme) => ({
  BlueColor: {
    color: "#3498db",
  },
  expandLeftPlace: {
    width: "100%",
    backgroundColor: "white",
    height: "100%!important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  MainDeleteMarginLeft: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px!important",
    },
  },

  ToolBarStyle: {
    backgroundColor: "#3498db",
    display: "flex",
  },
  ColorWhiteIcons: {
    color: "#ffffff",
  },
  MenuStyles: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  DrawerStyles: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  },
  DrawerHeaderStyles: {
    backgroundColor: "#3498db",
    color: "#ffffff",
  },
  DrawerListStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "5px",
    paddingTop: "10px",
  },
  DrawerProgress: {
    width: "100px!important",
    height: "100px!important",
  },
  textOfTitleCourse: {
    fontSize: "18px!important",
    fontWeight: "500!important",
  },
  textOfDescriptionCourse: {
    fontSize: "12px!important",
    fontWeight: "thin",
    color: "#c3c0bc",
  },
  ListItemIconStyles: {
    width: "24px",
    height: "24px",
    textAlign: "center",
    color: "#ffffff",
    borderRadius: "100%",
  },
  ListItemTextFont: {
    fontSize: "12px!important",
  },
  ListItemTypographyFont: {
    fontSize: "14px!important",
    fontWeight: "thin",
  },
  PointerOfModuleSection: {
    cursor: "pointer",
  },
}));

export const stylesTemplate1SectionMain = makeStyles((theme) => ({
  BackgroundSectionMain: {
    height: "calc(100vh - 56px)",
  },
  FirstSectionMain: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "20px",
  },
  cardExams: {
    maxWidth: "100%",
  },
  avatar: {
    backgroundColor: "#3498db",
  },
  content_img: {
    textAlign: "center",
  },
  image: {
    width: "50%",
    maxWidth: "200px",
    minWidth: "100px",
  },
  content_text: {
    padding: "0px 10px 0px 10px",
  },
  paperCard: {
    borderRadius: "25px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    "&:hover": {
      transition: "all 0.1s",
      transform: "scale(1.05)",
    },
  },
  FirstSectionMainLeft: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    boxShadow:
      "10px 10px 10px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
  },
  FirstSectionTittleLeft: {
    display: "flex",
    width: "100%",
    padding: "25px",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      display: "none!important",
    },
  },
  FirstSectionMainRight: {
    width: "20%",
    height: "100%",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none!important",
    },
  },
  FirstSecionMainRightButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "50%",
  },
  FirstSecionMainRightButtonsBack: {
    backgroundColor: "#3498db",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
  },
  FirstSecionMainRightButtonsNext: {
    backgroundColor: "#3498db",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  FirstSectionMainRightMobile: {
    width: "100%",
    marginTop: "20px",
    display: "none",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  FirstSecionMainRightButtonsMobile: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  FirstSecionMainRightButtonsMobileBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "50%",
  },
  FirstSecionMainRightButtonsNextMobile: {
    backgroundColor: "#3498db",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  FirstSecionMainRightButtonsBackMobile: {
    backgroundColor: "#3498db",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
  },
  Description: {
    width: "100%",
    padding: "0px 45px 45px 45px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 20px 20px",
    },
  },
  DescriptionHtml: {
    width: "100%",
    marginTop: "50px",
    color: "#3498db",
  },
  SectionMainFirstMount: {
    width: "100%",
    height: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 56px)",
    },
  },
  SectionPresentationOfCourse: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#131313",
  },
  SectionPresentationOfCourseBox: {
    display: "flex",
    flexDirection: "column",
  },
  SectionPresentationText: {
    color: "#3498db",
    fontSize: "1.125rem!important",
    fontWeight: "700!important",
    lineHeight: "1.75rem!important",
    marginBottom: ".5rem!important",
    textAlign: "justify",
  },
  SectionPresentationTextDescription: {
    color: "#3498db",
    fontSize: "1rem!important",
    lineHeight: "1.75rem!important",
    marginBottom: ".5rem!important",
    textAlign: "center",
  },
  SectionModulsTextDescription: {
    fontSize: "1rem!important",
    lineHeight: "1.75rem!important",
    marginBottom: ".5rem!important",
    textAlign: "justify",
  },
  ListTextAlignCenter: {
    textAlign: "center!important",
  },
  textDecoretionNone: {
    textDecoration: "none!important",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem!important",
  },
}));

export const stylesTemplate1ClientHome = makeStyles((theme) => ({
  BlueColor: {
    color: "#3498db",
  },
  BlueColorFull: {
    backgroundColor: "#3498db",
    color: "#fff",
  },
  CardsPadding: {
    padding: "16px!important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px!important",
    },
  },
  SectionMain: {
    display: "flex",
    justifyContent: "center",
    marginTop: "64px",
    height: "calc(100vh - 64px)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "56px",
      height: "calc(100vh - 56px)",
    },
  },
  SectionMainMiddleBox: {
    marginTop: "20px",
    width: "2400px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  SectionMainMiddleBoxTittle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  SectionMainMiddleBoxName: {
    color: "#3498db",
    fontSize: "1.125rem!important",
    fontWeight: "700!important",
    lineHeight: "1.75rem!important",
    marginBottom: ".5rem!important",
  },
  SectionMainMiddleBoxDescription: {
    color: "#3498db",
    fontSize: "1rem!important",
    lineHeight: "1.5rem!important",
    marginBottom: "1rem!important",
  },
  SectionMainBoxGraph: {
    backgroundColor: "#202020",
    display: "flex",
    justifyContent: "center",
    borderRadius: "25px",
    height: "250px",
    margin: "10px",
  },
  SectionMainBoxCourses: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  SectionMainBoxCoursesTittle: {
    color: "#3498db",
    fontSize: "1.125rem!important",
    fontWeight: "700!important",
    lineHeight: "1.75rem!important",
    marginBottom: ".5rem!important",
    padding: "15px",
  },
  SectionMainBoxCoursesCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  SectionMainBoxCoursesCardsBox: {
    width: "368px",
    height: "368px",
    backgroundColor: "#3498db !important",
    boxShadow:
      "10px 10px 10px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    margin: "18px",

    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "300px",
    },
  },
  SectionMainBoxCoursesCardsBoxDescription: {
    color: "#fff!important",
    overflow: "hidden",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  SectionMainBoxCoursesCardsBoxDescriptionApp: {
    color: "#fff!important",
    textAlign: "justify",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  SectionMainBoxCoursesCardsBoxState: {
    color: "white !important",
  },
  showDescriptionCourse: {
    cursor: "pointer",
    fontWeight: "bold",
    position: "relative",
    zIndex: "100",
  },

  SectionMainBoxCoursesCardsBoxTittle: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },
  SectionMainBoxCoursesCardsBoxImage: {
    height: "190px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "170px",
    },
  },
}));

export const stylesTemplate1Loading = makeStyles((theme) => ({
  boxLoading: {
    display: "flex",
    width: "100vw",
    height: "calc(100vh - 68px)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "68px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "60px",
      height: "calc(100vh - 58px)",
    },
  },
  boxLoadingMenu: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 56px)",
    },
  },
}));

export const stylesAppBarModal = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
  },
}));

export const stylesHeader = makeStyles((theme) => ({
  boxImage: {
    margin: "0 auto",
    textAlign: "center",
  },

  boxFormInput: {
    marginTop: "15px",
  },
  boxFormButton: {
    marginTop: "15px",
    backgroundColor: "#3498db",
    color: "white",
    fontWeight: "bold",
  },
  loading: {
    position: "absolute",
    left: "0",
    marginLeft: "10px",
  },
}));

export function CircularProgressWithLabel(props) {
  let percentage = Math.round(props.value);
  return (
    <Box sx={{ position: "relative", display: "inline-flex", width: "150px" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: "#2ED7F2",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent",
        })}
      />
    </Box>
  );
}

export const StyleCircularProgressForModals = makeStyles((theme) => ({
  progressLoading: {
    height: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyleForClientContentModul = makeStyles((theme) => ({
  sectionBox: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const StylesMessages = makeStyles((theme) => ({
  boxBody: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
}));

export const StylesForModalScore = makeStyles((theme) => ({
  bodyBox: {
    width: "100%",
    height: "125px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxOfStars: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
