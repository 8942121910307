import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete, BtnDetalle } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { consulta, viewError, formulario, Toast, confirmAction } from "../../global/js/funciones";
import ExamQuestionAdd from "./ExamQuestionAdd";
import ExamAnswer from "./ExamAnswer";
import moment from "moment";
import { QUESTION } from "../general/helperGeneric";



class ExamQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      question: [],
      processQuestion: "",
    };
  }

  async componentDidUpdate({ modalListExamQuestion, modalAddExamQuestion }) {
    let { modalListExamQuestion: modalListExamQuestionNew } = this.props;
    if (modalListExamQuestionNew !== modalListExamQuestion) {
      this.data();
    }
    let { modalAddExamQuestion: modalAddExamQuestionNew } = this.props;
    if (modalAddExamQuestionNew !== modalAddExamQuestion) {
      this.data();
    }
  }

  async data() {
    this.setState({ charging: true });
    let question = await this.listQuestion();
    this.setState({ question, charging: false });
  }

  async listQuestion() {
    let { process } = this.props;
    let data = process.id;
    let f = QUESTION; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${data}/permission`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  deleteQuestion = async (info) => {
    let f = info.principal.id;
    let data = info.secondary;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/${data}/permission`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  addPermissionGeneric = async (data) => {
    let { process, showModalAddExamQuestion } = this.props;
    data.append("secondary", process.id);
    formulario(
      `api/v1.0/values/permission/add/exam`,
      data,
      "post",
      (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.",
          types = "error";
        if (!error) {
          if (estado === 200) {
            types = "success";
            title = resp.title;
            showModalAddExamQuestion(false);
            this.data();
          } else {
            types = "info";
            title = resp.title ? resp.title : viewError(resp);
          }
        }
        Toast.fire({ icon: types, title });
      }
    );
  };

 

  view() {
    let { charging, question, processQuestion } = this.state;
    let {
      showModalListExamAnswer,
      modalListExamAnswer,
      showModalAddExamAnswer,
      modalAddExamAnswer,
    } = this.props;
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteQuestion(data));
      };

      const onClickDetail = (data) => {
        this.setState({ processQuestion: data });
        showModalListExamAnswer(true);
      };

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;
      let detail = (
        <BtnDetalle color="#01579b" callback={() => onClickDetail(rowData)} />
      );
      return (
        <div>
          {detail}
          {delet}
        </div>
      );
    };
    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_categories"
          datos={question}
          acciones={(row) => actions(row)}
          fila_principal={({ principal: { name } }) => name.toUpperCase()}
          filas={[
            {
              id: "date_add",
              enLista: true,
              show: ({ principal: { date_add } }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
            },
          ]}
        />
        <ExamAnswer
          process={processQuestion}
          showModalListExamAnswer={showModalListExamAnswer}
          modalListExamAnswer={modalListExamAnswer}
          showModalAddExamAnswer={showModalAddExamAnswer}
          modalAddExamAnswer={modalAddExamAnswer}
          addPermissionGeneric={this.addPermissionGeneric}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListExamQuestion,
      modalListExamQuestion,
      showModalAddExamQuestion,
      modalAddExamQuestion,
    } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListExamQuestion}
          onClose={() => showModalListExamQuestion(false)}
        >
          <AppBarModal
            title={"¡ Preguntas del Examen ! "}
            showMessageModal={() => showModalListExamQuestion(false)}
            title_accion="Cerrar"
            accion={() => showModalListExamQuestion(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              <Fab
                style={{ zIndex: 1, position: "relative" }}
                color="primary"
                aria-label="add"
                onClick={() => showModalAddExamQuestion(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
        <ExamQuestionAdd
          showModalAddExamQuestion={showModalAddExamQuestion}
          modalAddExamQuestion={modalAddExamQuestion}
          addPermissionGeneric={this.addPermissionGeneric}
        />
      </>
    );
  }
}
ExamQuestion.propTypes = {
  modalListExamQuestion: PropTypes.bool.isRequired,
  modalAddExamQuestion: PropTypes.bool.isRequired,
  modalListExamAnswer: PropTypes.bool.isRequired,
  modalAddExamAnswer: PropTypes.bool.isRequired,
};

export default ExamQuestion;
