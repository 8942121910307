import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
} from "@material-ui/core";
import LinearProgressWithLabel from "../general/BarProgress";
import AddIcon from "@material-ui/icons/Add";
import { consulta, viewError, Toast, confirmAction } from "../../global/js/funciones";
import CourseModulesAdd from "./CourseModulesAdd";
import moment from "moment";
import { MODULES } from "../general/helperGeneric";



class CourseModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      add: true,
      modules: [],
      weightTotal: 0,
    };
  }

  async componentDidUpdate({
    modalListCoursesModules,
    modalAddCoursesModules,
  }) {
    let { modalListCoursesModules: modalListCoursesModulesNew } = this.props;
    if (modalListCoursesModulesNew !== modalListCoursesModules) {
      this.data();
    }
    let { modalAddCoursesModules: modalAddCoursesModulesNew } = this.props;
    if (modalAddCoursesModulesNew !== modalAddCoursesModules) {
      this.data();
    }
  }

  async data() {
    this.setState({ charging: true });
    let modules = await this.listModules();
    this.setState({ modules, charging: false });
    let weightTotal = 0;
    modules.forEach((element) => {
      weightTotal = weightTotal + parseFloat(element.weight);
    });
    if (weightTotal >= 100) {
      this.setState({ add: false, weightTotal });
    } else {
      this.setState({ add: true, weightTotal });
    }
  }

  async listModules() {
    let { process } = this.props;
    let data = process.id;
    let f = MODULES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${data}/permission`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  deleteModule = async (info)=> {
    let f = info.principal.id;
    let data = info.secondary;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/${data}/permission`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  addPermissionGeneric = async (data) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/permission/add`,
        data,
        "post",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  };

  

  view() {
    let { charging, modules } = this.state;
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteModule(data));
      };

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;
      return <div>{delet}</div>;
    };

    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_categories"
          datos={modules}
          acciones={(row) => actions(row)}
          fila_principal={({ principal: { name } }) => name.toUpperCase()}
          filas={[
            {
              id: "weight",
              enLista: true,
              show: ({ weight }) => `${weight} %`,
            },
            {
              id: "date_add",
              enLista: true,
              show: ({ principal: { date_add } }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListCoursesModules,
      modalListCoursesModules,
      showModalAddCoursesModules,
      modalAddCoursesModules,
      process,
      showModalAddCoursesModulesWeight,
      modalAddCoursesModulesWeight,
    } = this.props;
    let { add, weightTotal } = this.state;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListCoursesModules}
          onClose={() => showModalListCoursesModules(false)}
        >
          <AppBarModal
            title={"¡ Modulos Curso ! "}
            showMessageModal={() => showModalListCoursesModules(false)}
            title_accion="Cerrar"
            accion={() => showModalListCoursesModules(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              {add && (
                <Fab
                  style={{ zIndex: 1, position: "relative" }}
                  color="primary"
                  aria-label="add"
                  onClick={() => showModalAddCoursesModules(true)}
                >
                  <AddIcon />
                </Fab>
              )}
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={weightTotal} />
              </Box>
            </Grid>
          </DialogActions>
        </Dialog>
        <CourseModulesAdd
          process={process}
          weightTotal={weightTotal}
          showModalAddCoursesModules={showModalAddCoursesModules}
          modalAddCoursesModules={modalAddCoursesModules}
          showModalAddCoursesModulesWeight={showModalAddCoursesModulesWeight}
          modalAddCoursesModulesWeight={modalAddCoursesModulesWeight}
          addPermissionGeneric={this.addPermissionGeneric}
        />
      </>
    );
  }
}
CourseModules.propTypes = {
  modalListCoursesModules: PropTypes.bool.isRequired,
  modalAddCoursesModules: PropTypes.bool.isRequired,
  modalAddCoursesModulesWeight: PropTypes.bool.isRequired,
};

export default CourseModules;
