import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { BtnForm } from "../general/ButtonAccion";
import { ANSWER } from "../general/helperGeneric";
import {Toast } from "../../global/js/funciones";

class ExamAnswerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      names: { value: "", error: false },
    };
  }

  async componentDidUpdate() {}

  reset() {
    this.setState({ names: { value: "", error: false } });
  }

  onSubmit = (e) => {
    let { names } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!names.value) {
      names.error = obligatorio;
      error = true;
      this.setState({names:{error: true}})
    }
    if (!error) {
      this.sendData();
    } else
    Toast.fire({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
      });
  };

  sendData() {
    let { names } = this.state;
    const data = new FormData();
    data.append("name", names.value);
    data.append("type_data", 0);
    data.append("generic", ANSWER);

    this.props.addPermissionGeneric(data, () =>
      this.setState({ charging: false })
    );
    this.reset();
  }

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: { value: value },
    });
  };

  onChangeSimple = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  conError(valor) {
    return valor ? true : false;
  }

  view() {
    let { names } = this.state;

    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: "40px" }}>
          <Grid item xs={12}>
            <TextField
              value={names.value}
              required
              type="text"
              id="names"
              name="names"
              label="Respuesta"
              error={this.conError(names.error)}
              helperText={names.error}
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    let { showModalAddExamAnswer, modalAddExamAnswer } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={modalAddExamAnswer}
          onClose={() => showModalAddExamAnswer(false)}
        >
          <AppBarModal
            title={" ¡Agregar Respuestas! "}
            showMessageModal={() => showModalAddExamAnswer(false)}
            title_accion="Cerrar"
            accion={() => showModalAddExamAnswer(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddExamAnswer(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
ExamAnswerAdd.propTypes = {
  modalAddExamAnswer: PropTypes.bool.isRequired,
};

export default ExamAnswerAdd;
