import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
  },
}));

const CrearAccion = (title_accion, accion) => {
  const resp = accion ? (
    <Button style={{ color: "white" }} onClick={accion.bind(this, false)}>
      {" "}
      {title_accion}{" "}
    </Button>
  ) : (
    ""
  );
  return resp;
};

export default function AppBarModal({
  title,
  showMessageModal,
  title_accion = "Cerrar",
  accion,
}) {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {CrearAccion(title_accion, accion)}
      </Toolbar>
    </AppBar>
  );
}

AppBarModal.propTypes = {
  //funciones
  title: PropTypes.string.isRequired,
  showMessageModal: PropTypes.func.isRequired,
  title_accion: PropTypes.string.isRequired,
  accion: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
