import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, TextField, Button, AppBar } from "@material-ui/core";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import { Loading } from "./Template1Loadings";
import {
  getOneValueOfValuesGenerics,
  TransformText,
} from "../../global/js/Requests";
import { api } from "../../global/js/funciones";
import Template1Modal from "./Template1Modal";
//Funtions

function AddPhone({ phone, setPhone, errores, send, data = { data } }) {
  return (
    <div>
      <Grid container>
        {send ? (
          <Grid item xs={12} sm={12} md={12}>
            <p style={{ textAlign: "center", fontSize: 15 }}>
              {
                <span>
                  <br />
                  En breve recibirás un mensaje en tu pantalla. Haz clic en{" "}
                  <b>ACEPTAR</b> para continuar con tu solicitud de inscripción
                  a {data.nombre}, con los mejores contenidos y noticias. No
                  olvides recargar tu línea para seguir disfrutando de nuestros
                  servicios. <br />
                </span>
              }
            </p>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12}>
            <p style={{ textAlign: "center", fontSize: 15 }}>
              {" "}
              Para suscribirte a este sitio web por favor ingresa tu número de
              teléfono aquí. Te enviaremos un SMS de Bienvenida con toda la
              información.
            </p>
            <TextField
              onChange={({ target: { value } }) => setPhone(value)}
              value={phone}
              type="number"
              color={"secondary"}
              // error={getError("phone", errores) && true}
              // helperText={getError("phone", errores)}
              label="Ingresa tu número de teléfono"
              fullWidth={true}
              required
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

function Header({ suscription }) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ padding: "50px 0 30px 0" }}
    >
      <img
        src={`${api}${suscription.files}`}
        alt=""
        style={{ maxWidth: 300, maxHeight: 300 }}
      />
    </Grid>
  );
}

export default function Suscription() {
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [modalSuscripcion, setModalSuscripcion] = useState(false);
  const [phone, setPhone] = useState("");
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [errores, setErrores] = useState([]);
  const [pk] = useState(params.id);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setWait(true);
    let { resp, status } = await getOneValueOfValuesGenerics(pk);
    if (status == 200) {
      setData(resp);
      setAutomatic(false);
    } else setError(true);
    setWait(false);
  };

  const iniciarSpeed = async (phone, callback = () => {}, id_scen) => {
    let errores = [];
    if (errores.length == 0) {
      callback(true);
      fetch(
        `http://3.140.140.79:10020/subscription-claro/gemalto?msisdn=${
          "57" + phone
        }&idScen=${id_scen}`
      )
        .then((response) => response.json())
        .then((data) => console.log(data));
      setSend(true);
      callback(false);
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "white" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginBottom: 50 }}
      >
        {wait ? (
          <Loading />
        ) : (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Header suscription={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
                  <p style={{ textAlign: "center", fontSize: 15 }}>
                    <br />
                    Haz clic en <b>INICIAR SUSCRIPCIÓN</b> para continuar con tu
                    solicitud de inscripción a {data.name}, con los mejores
                    cursos
                    <br />
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
              <h4 style={{ textAlign: "center", fontSize: 15 }}>
                TERMINOS Y CONDICIONES
              </h4>
              {data && data.valuea ? <TransformText text={data.valuea} /> : ""}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Template1Modal
        // nameBtn1={!send && "ACEPTAR"}
        // nameBtn2="CERRAR"
        disabledBtnSuccess={phone.length == 0}
        open={modalSuscripcion}
        setOpen={setModalSuscripcion}
        title={`Iniciar Suscripción`}
        showBthSuccess={!send}
        content={
          <AddPhone
            phone={phone}
            setPhone={setPhone}
            errores={errores}
            send={send}
            data={data}
          />
        }
        callback={(setWaitModal) => {
          iniciarSpeed(phone, setWaitModal, data.id_scen);
        }}
      />
      {!automatic && (
        <AppBar
          position="fixed"
          color="primary"
          style={{
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            padding: 20,
          }}
          elevation={2}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubscriptionsIcon />}
            style={{ width: 300, margin: "0 auto", backgroundColor: "#3498db" }}
            onClick={() => {
              setSend(false);
              setModalSuscripcion(true);
            }}
          >
            INICIAR SUSCRIPCIÓN
          </Button>
        </AppBar>
      )}
    </Grid>
  );
}
