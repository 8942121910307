import React, { Component } from "react";
import { connect } from "react-redux";
import MenuTop from "../general/MenuTop";
import MenuBotton from "../general/MenuBotton";
import {
  CircularProgress,
  Fab,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import {
  showModalAddSubscriptions,
  showModalEditSubscriptions,
  showModalListCoursesSuscription,
  showModalAddCoursesSuscription,
  showModalListSuscriptionDetail,
} from "../../redux/actions/actGenerics";
import AppBarModal from "../general/AppBarModal";
import {
  consulta,
  viewError,
  formulario,
  api,
  Toast, 
  confirmAction
} from "../../global/js/funciones";
import AddIcon from "@material-ui/icons/Add";
import CreateSubscriptions from "./CreateSubscriptions";
import EditSubscriptions from "./EditSubscriptions";
import SuscriptionCourses from "./SuscriptionCourses";
import { SUBSCRIPTIONS, TEMPLATE } from "../general/helperGeneric";
import ListarDatos from "../general/ListarDatos";
import {
  BtnDelete,
  BtnEdit,
  BtnBookMark,
  BtnDetalle,
  BtnForm,
} from "../general/ButtonAccion";
import moment from "moment";
import producto from "../../global/images/producto.png";
import referencia from "../../global/images/referencia.png";
import template from "../../global/images/template.png";

function DetailSuscription(component_props) {
  let { showModalListSuscriptionDetail, modalListSuscriptionDetail, process } =
    component_props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={modalListSuscriptionDetail}
      onClose={() => showModalListSuscriptionDetail(false)}
    >
      <AppBarModal
        title={" ¡ Detalle Suscripción ! "}
        showMessageModal={() => showModalListSuscriptionDetail(false)}
        title_accion="Cerrar"
        accion={() => showModalListSuscriptionDetail(false)}
      />
      <DialogContent className="scroll">
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={producto}
                  alt="producto"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Producto
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {process.product}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={referencia}
                  alt="referencia"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Referencia
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {process.reference}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={template}
                  alt="template"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Plantilla
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {process.template}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div">
              idScen: {process.id_scen}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div">
              Periodicidad: {process.periodicity}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: "#D6FED8" }}>
            <Typography gutterBottom variant="h6" component="div">
              Terminos y Condiciones
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ textAlign: "justify" }}
            >
              {process.valuea}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="div">
              Politica de Datos
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ textAlign: "justify" }}
            >
              {process.valueb}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm
          text="CANCELAR"
          variant="outlined"
          callback={() => showModalListSuscriptionDetail(false)}
        />
      </DialogActions>
    </Dialog>
  );
}

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      valuesGenerics: [],
      process: "",
      templates: [],
    };
  }

  async componentDidMount() {
    this.data();
  }
  async data() {
    this.setState({ charging: true });
    let valuesGenerics = await this.listValuesGenerics();
    let templates = await this.listValuesGenericsTemplate();
    this.setState({ valuesGenerics, templates, charging: false });
  }

  async listValuesGenerics() {
    let f = SUBSCRIPTIONS; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async listValuesGenericsTemplate() {
    let f = TEMPLATE; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  valuesGenericsChangeState = (state) => {
    this.setState({ valuesGenerics: state });
  };

  saveValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalAddSubscriptions } = this.props;
    formulario(`api/v1.0/values/crear`, data, "post", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalAddSubscriptions(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };

  editValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalEditSubscriptions } = this.props;
    formulario(`api/v1.0/values/editar`, data, "put", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalEditSubscriptions(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };

  deleteValueGeneric= async (info) =>  {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  
  view() {
    let { valuesGenerics } = this.state;
    let {
      showModalAddSubscriptions,
      showModalEditSubscriptions,
      showModalListCoursesSuscription,
      showModalListSuscriptionDetail,
    } = this.props;

     
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteValueGeneric(data));
      };
      const onClickEdit = (process) => {
        this.setState({ process });
        showModalEditSubscriptions(true);
      };
      const onClickCourses = (process) => {
        this.setState({ process });
        showModalListCoursesSuscription(true);
      };

      const onClickDetalle = (process) => {
        this.setState({ process });
        showModalListSuscriptionDetail(true);
      };

      let edit = (
        <BtnEdit
          color="#760068"
          callback={() => onClickEdit(rowData)}
          texto="Editar"
        />
      );
      let courses = (
        <BtnBookMark
          color="#10895D"
          callback={() => onClickCourses(rowData)}
          texto="Cursos"
        />
      );
      let detail = (
        <BtnDetalle callback={() => onClickDetalle(rowData)} texto="Detalle" />
      );
      let delet = (
        <BtnDelete
          color="#C62300"
          callback={() => onClickDelete(rowData)}
          texto="Eliminar"
        />
      );
      return (
        <div>
          {courses}
          {detail}
          {edit}
          {delet}
        </div>
      );
    };

    return (
      <>
        <Grid style={{ paddingTop: 65, marginBottom: 80 }}>
          <ListarDatos
            id="tbl_list_generic_categories"
            datos={valuesGenerics}
            avatarImg={true}
            avatar={({ files }) => `${api}${files}`}
            acciones={(row) => actions(row)}
            fila_principal={({ name }) => name.toUpperCase()}
            filas={[
              {
                id: "codigo",
                enLista: true,
                show: ({ id }) => `Codigo: ${id}`,
              },
              {
                id: "description",
                enLista: true,
                show: ({ description }) => description,
              },
              {
                id: "date_add",
                enLista: true,
                show: ({ date_add }) =>
                  moment(date_add).format("YYYY-MM-DD, h:mm a"),
              },
            ]}
          />
        </Grid>
        <Fab
          style={{ zIndex: 2, position: "fixed", bottom: 50, right: 20 }}
          color="primary"
          aria-label="add"
          onClick={() => showModalAddSubscriptions(true)}
        >
          <AddIcon />
        </Fab>
      </>
    );
  }
  render() {
    let { charging, process, valuesGenerics, templates } = this.state;
    let {
      showModalAddSubscriptions,
      modalAddSubscriptions,
      showModalEditSubscriptions,
      modalEditSubscriptions,
      modalAddCoursesSuscription,
      modalListCoursesSuscription,
      showModalListCoursesSuscription,
      showModalAddCoursesSuscription,
      showModalListSuscriptionDetail,
      modalListSuscriptionDetail,
    } = this.props;
    return (
      <>
        <MenuTop
          label="suscripciones"
          valuesGenericsChangeState={this.valuesGenericsChangeState}
          valuesGenerics={valuesGenerics}
          data={SUBSCRIPTIONS}
        />
        <MenuBotton value={0} />
        {!charging ? (
          this.view()
        ) : (
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 300, maxWidth: "100%" }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        <CreateSubscriptions
          showModalAddSubscriptions={showModalAddSubscriptions}
          modalAddSubscriptions={modalAddSubscriptions}
          templates={templates}
          saveValueGeneric={this.saveValueGeneric}
        />
        <EditSubscriptions
          process={process}
          templates={templates}
          showModalEditSubscriptions={showModalEditSubscriptions}
          modalEditSubscriptions={modalEditSubscriptions}
          editValueGeneric={this.editValueGeneric}
        />
        <SuscriptionCourses
          process={process}
          showModalListCoursesSuscription={showModalListCoursesSuscription}
          modalListCoursesSuscription={modalListCoursesSuscription}
          showModalAddCoursesSuscription={showModalAddCoursesSuscription}
          modalAddCoursesSuscription={modalAddCoursesSuscription}
        />
        <DetailSuscription
          {...{
            process,
            showModalListSuscriptionDetail,
            modalListSuscriptionDetail,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let {
    modalAddSubscriptions,
    modalEditSubscriptions,
    modalListCoursesSuscription,
    modalAddCoursesSuscription,
    modalListSuscriptionDetail,
  } = state.redGenerics;
  return {
    modalAddSubscriptions,
    modalEditSubscriptions,
    modalListCoursesSuscription,
    modalAddCoursesSuscription,
    modalListSuscriptionDetail,
  };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  showModalAddSubscriptions,
  showModalEditSubscriptions,
  showModalListCoursesSuscription,
  showModalAddCoursesSuscription,
  showModalListSuscriptionDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
