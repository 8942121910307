import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BtnForm } from "../general/ButtonAccion";
import { QUESTION } from "../general/helperGeneric";
import {Toast } from "../../global/js/funciones";

class ExamQuestionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      names: { value: "", error: false },
      typeQuestion: "",
    };
  }

  async componentDidUpdate() {}

  reset() {
    this.setState({ names: { value: "", error: false }, typeQuestion: "" });
  }

  onSubmit = (e) => {
    let { names, typeQuestion } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!names.value) {
      names.error = obligatorio;
      error = true;
      this.setState({names:{error: true}})
    }
    if (!typeQuestion) {
      error = true;
    }
    if (!error) {
      this.sendData();
    } else
        Toast.fire({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
      });
  };

  sendData() {
    let { names, typeQuestion } = this.state;
    const data = new FormData();
    data.append("name", names.value);
    data.append("type_data", typeQuestion);
    data.append("generic", QUESTION);

    this.props.addPermissionGeneric(data, () =>
      this.setState({ charging: false })
    );
    this.reset();
  }

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: { value: value },
    });
  };
  onChangeSimple = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  conError(valor) {
    return valor ? true : false;
  }

  view() {
    let { names, typeQuestion } = this.state;

    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: "40px" }}>
          <Grid item xs={12}>
            <TextField
              value={names.value}
              required
              type="text"
              id="names"
              name="names"
              label="Pregunta"
              error={this.conError(names.error)}
              helperText={names.error}
              fullWidth
              onChange={this.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-control" required fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                id="typeQuestion"
                value={typeQuestion}
                onChange={this.onChangeSimple}
                name="typeQuestion"
              >
                <MenuItem value="2">Seleccion Unica Respuesta</MenuItem>
                <MenuItem value="3">Seleccion Multiple</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    let { showModalAddExamQuestion, modalAddExamQuestion } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={modalAddExamQuestion}
          onClose={() => showModalAddExamQuestion(false)}
        >
          <AppBarModal
            title={" ¡Agregar Preguntas! "}
            showMessageModal={() => showModalAddExamQuestion(false)}
            title_accion="Cerrar"
            accion={() => showModalAddExamQuestion(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddExamQuestion(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
ExamQuestionAdd.propTypes = {
  modalAddExamQuestion: PropTypes.bool.isRequired,
};

export default ExamQuestionAdd;
