import React, { Component } from "react";
import { BrowserRouter as Brouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { styleApp } from "./components/general/StyleApp";
import "./App.css";
import Messages from "./components/general/Messages";
import { ReactNotifications } from "react-notifications-component";
import Login from "./components/general/app/Login";
import Categories from "./components/categories/Categories";
import Modules from "./components/modules/Modules";
import Subscriptions from "./components/subscriptions/Subscriptions";
import Courses from "./components/courses/Courses";
import Exam from "./components/exam/Exam";
import { Template1 } from "./components/templates/Template1";
import Suscription from "./components/templates/Suscription";
import NotFound from "./components/general/app/NotFound";
import User from "./components/users/User";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ReactNotifications />

        <MuiThemeProvider theme={styleApp}>
          <Brouter>
            <Routes>
              <Route path="/admin" element={<Subscriptions />} />
              <Route path="/admin/ingresar" element={<Login />} />
              <Route path="/admin/usuarios" element={<User />} />
              <Route path="/admin/sin_sesion" element={<Login />} />
              <Route path="/admin/cursos" element={<Courses />} />
              <Route path="/admin/categorias" element={<Categories />} />
              <Route path="/admin/modulos" element={<Modules />} />
              <Route path="/admin/suscripciones" element={<Subscriptions />} />
              <Route path="/admin/examenes" element={<Exam />} />
              <Route path="/:name/:id" element={<Template1 />} />
              <Route path="/:name/:id/suscripcion" element={<Suscription />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Messages />
          </Brouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
