import { createStore, combineReducers } from "redux";
import redGenerics from './reducers/redGenerics';
import redGlobal from './reducers/redGlobal';

const reducer = combineReducers({
  redGenerics,
  redGlobal,
});

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
