import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Checkbox,
  DialogActions,
} from "@material-ui/core";
import { consulta } from "../../global/js/funciones";
import moment from "moment";
import { BtnForm } from "../general/ButtonAccion";
import { CATEGORIES } from "../general/helperGeneric";

class CourseCategoriesAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      categories: [],
      checked: [],
    };
  }

  async componentDidUpdate({ modalAddCoursesCategories }) {
    let { modalAddCoursesCategories: modalAddCoursesCategoriesNew } =
      this.props;
    if (modalAddCoursesCategoriesNew !== modalAddCoursesCategories) {
      this.data();
    }
  }

  async data() {
    this.setState({ charging: true });
    let categories = await this.listCategories();
    this.setState({ categories, charging: false });
  }

  async listCategories() {
    let f = CATEGORIES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  reset() {
    this.setState({ checked: [] });
  }
  onSubmit = (e) => {
    let { checked } = this.state;
    let { process, showModalAddCoursesCategories } = this.props;
    let data = [];
    let info;
    checked.map((id) =>
      data.push({
        principal: id,
        secondary: process.id,
        weight: null,
        weightTotal: 0,
      })
    );
    info = { data };
    this.props.addPermissionGeneric(info, () =>
      this.setState({ charging: false })
    );
    showModalAddCoursesCategories(false);
    this.reset();
  };

  view() {
    let { charging, categories, checked } = this.state;
    const handleChange = (data) => async () => {
      const currentIndex = checked.indexOf(data.id);
      let newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(data.id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      this.setState({ checked: newChecked });
    };

    const actions = (data) => {
      const detail = (
        <Checkbox
          edge="end"
          checked={checked.indexOf(data.id) !== -1}
          onChange={handleChange(data)}
        />
      );
      return detail;
    };
    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_categories"
          datos={categories}
          acciones={(row) => actions(row)}
          fila_principal={({ name }) => name.toUpperCase()}
          filas={[
            {
              showMessage: ({ date_add }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
              id: "date_add",
              enLista: true,
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let { showModalAddCoursesCategories, modalAddCoursesCategories } =
      this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={modalAddCoursesCategories}
          onClose={() => showModalAddCoursesCategories(false)}
        >
          <AppBarModal
            title={" ¡Agregar Categorias! "}
            showMessageModal={() => showModalAddCoursesCategories(false)}
            title_accion="Cerrar"
            accion={() => showModalAddCoursesCategories(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddCoursesCategories(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
CourseCategoriesAdd.propTypes = {
  modalAddCoursesCategories: PropTypes.bool.isRequired,
};

export default CourseCategoriesAdd;
