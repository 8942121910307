import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete, BtnDetalle } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
} from "@material-ui/core";
import LinearProgressWithLabel from "../general/BarProgress";
import AddIcon from "@material-ui/icons/Add";
import {
  consulta,
  formulario,
  api,
  viewError,
  Toast,
  confirmAction
} from "../../global/js/funciones";
import moment from "moment";
import CreateContentsModules from "./CreateContentsModules";
import swal from "sweetalert";


class ModuleContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      contents: [],
      processContents: "",
      add: true,
      weightTotal: 0,
    };
  }

  async componentDidUpdate({
    modalListModulesContents,
  }) {
    let { modalListModulesContents: modalListModulesContentsNew, process } = this.props;
    if (modalListModulesContentsNew !== modalListModulesContents) {
      this.data();
      this.setState({ processContents: process.id });
    }
    
  }

  async data() {
    this.setState({ charging: true });
    let contents = await this.listContents();
    this.setState({ contents});
    let weightTotal = 0;
    contents.forEach((element) => {
      weightTotal = weightTotal + parseFloat(element.weight);
    });
    if (weightTotal >= 100) {
      this.setState({ add: false, weightTotal });
    } else {
      this.setState({ add: true, weightTotal });
    }
    this.setState({ charging: false });
  }

  async listContents() {
    let { process } = this.props;
    let f = process.id; //Aqui debe ir el helper
    let type = 2; // tipos del archivo si es material = 1  y si es contenido =2
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${type}/file`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  deleteContents = async (info) => {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/file`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  saveValueGenericFile = async (data) => {
    this.setState({ charging: true });
    let { weightTotal } = this.state;
    data.append("weightTotal", weightTotal);
    return new Promise((resolve) => {
      formulario(
        `api/v1.0/values/files/add`,
        data,
        "post",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
          this.setState({ charging: false });
        }
      );
    });
  };


  view() {
    let { charging, contents,  } = this.state;

    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteContents(data));
      };

      let detail = (
        <a
          href={`${api}${rowData.files}`}
          target="_blank"
          rel="noopener noreferrer"
          className="sin_decorador"
        >
          <BtnDetalle callback={() => {}} color="#01579b" texto="Abrir" />
        </a>
      );

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;
      return (
        <div>
          {detail}
          {delet}
        </div>
      );
    };

    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_modules_contents"
          datos={contents}
          acciones={(row) => actions(row)}
          fila_principal={({ name }) => name.toUpperCase()}
          filas={[
            {
              id: "weight",
              enLista: true,
              show: ({ weight }) => `${weight} %`,
            },
            {
              id: "date_add",
              enLista: true,
              show: ({ date_add }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListModulesContents,
      modalListModulesContents,
      showModalAddModulesContents,
      modalAddModulesContents,
    } = this.props;
    let { processContents, add, weightTotal } = this.state;

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListModulesContents}
          onClose={() => showModalListModulesContents(false)}
        >
          <AppBarModal
            title={" ¡Contenido del Modulo ! "}
            showMessageModal={() => showModalListModulesContents(false)}
            title_accion="Cerrar"
            accion={() => showModalListModulesContents(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              {add && (
                <Fab
                  style={{ zIndex: 1, position: "relative" }}
                  color="primary"
                  aria-label="add"
                  onClick={() => showModalAddModulesContents(true)}
                >
                  <AddIcon />
                </Fab>
              )}
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={weightTotal} />
              </Box>
            </Grid>
          </DialogActions>
        </Dialog>
        <CreateContentsModules
          processContents={processContents}
          showModalAddModulesContents={showModalAddModulesContents}
          modalAddModulesContents={modalAddModulesContents}
          saveValueGenericFile={this.saveValueGenericFile}
        />
      </>
    );
  }
}
ModuleContents.propTypes = {
  modalListModulesContents: PropTypes.bool.isRequired,
  modalAddModulesContents: PropTypes.bool.isRequired,
};

export default ModuleContents;
