import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete, BtnDetalle } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  consulta,
  formulario,
  api,
  viewError,
  Toast,
  confirmAction
} from "../../global/js/funciones";
import moment from "moment";
import CreateMaterialModules from "./CreateMaterialModules";


class ModuleMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      material: [],
      processMaterial: "",
    };
  }

  async componentDidUpdate({
    modalListModulesMaterial,
  }) {
    let { modalListModulesMaterial: modalListModulesMaterialNew, process } =this.props;
    if (modalListModulesMaterialNew !== modalListModulesMaterial) {
      this.data();
      this.setState({ processMaterial: process.id });
    }
  }

  async data() {
    this.setState({ charging: true });
    let material = await this.listMaterial();
    this.setState({ material, charging: false });
  }

  async listMaterial() {
    let { process } = this.props;
    let f = process.id; //Aqui debe ir el helper
    let type = 1; // tipo del archivo si es material = 1  y si es contenido =2
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${type}/file`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  deleteMaterial = async (info)  => {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/file`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  saveValueGenericFile = async (data) => {
    this.setState({ charging: true });
    return new Promise((resolve) => {
      formulario(
        `api/v1.0/values/files/add`,
        data,
        "post",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
          this.setState({ charging: false });
        }
      );
    });
  };


  view() {
    let { charging, material } = this.state;

    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteMaterial(data));
      };

      let detail = (
        <a
          href={`${api}${rowData.files}`}
          target="_blank"
          rel="noopener noreferrer"
          className="sin_decorador"
        >
          <BtnDetalle callback={() => {}} color="#01579b" texto="Abrir" />
        </a>
      );

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;
      return (
        <div>
          {detail}
          {delet}
        </div>
      );
    };

    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_modules_material"
          datos={material}
          acciones={(row) => actions(row)}
          fila_principal={({ name }) => name.toUpperCase()}
          filas={[
            {
              id: "date_add",
              enLista: true,
              show: ({ date_add }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListModulesMaterial,
      modalListModulesMaterial,
      showModalAddModulesMaterial,
      modalAddModulesMaterial,
    } = this.props;
    let { processMaterial } = this.state;

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListModulesMaterial}
          onClose={() => showModalListModulesMaterial(false)}
        >
          <AppBarModal
            title={" ¡Materiales del Modulo ! "}
            showMessageModal={() => showModalListModulesMaterial(false)}
            title_accion="Cerrar"
            accion={() => showModalListModulesMaterial(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              <Fab
                style={{ zIndex: 1, position: "relative" }}
                color="primary"
                aria-label="add"
                onClick={() => showModalAddModulesMaterial(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
        <CreateMaterialModules
          processMaterial={processMaterial}
          showModalAddModulesMaterial={showModalAddModulesMaterial}
          modalAddModulesMaterial={modalAddModulesMaterial}
          saveValueGenericFile={this.saveValueGenericFile}
        />
      </>
    );
  }
}
ModuleMaterial.propTypes = {
  modalListModulesMaterial: PropTypes.bool.isRequired,
  modalAddModulesMaterial: PropTypes.bool.isRequired,
};

export default ModuleMaterial;
