import React, { Component } from "react";
import { connect } from "react-redux";
import MenuTop from "../general/MenuTop";
import MenuBotton from "../general/MenuBotton";
import { CircularProgress, Fab, Grid } from "@material-ui/core";
import {
  showModalAddCourses,
  showModalEditCourses,
  showModalListCoursesCategories,
  showModalAddCoursesCategories,
  showModalListCoursesModules,
  showModalAddCoursesModules,
  showModalAddCoursesModulesWeight,
} from "../../redux/actions/actGenerics";
import {
  consulta,
  viewError,
  formulario,
  api,
} from "../../global/js/funciones";
import AddIcon from "@material-ui/icons/Add";
import CreateCourses from "./CreateCourses";
import CourseCategories from "./CourseCategories";
import CourseModules from "./CourseModules";
import EditCourses from "./EditCourses";
import { COURSES } from "../general/helperGeneric";
import ListarDatos from "../general/ListarDatos";
import {
  BtnDelete,
  BtnEdit,
  BtnBookMark,
  BtnCloud,
} from "../general/ButtonAccion";
import moment from "moment";
import {Toast, confirmAction } from "../../global/js/funciones";

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      valuesGenerics: [],
      process: "",
    };
  }

  async componentDidMount() {
    this.data();
  }
  async data() {
    this.setState({ charging: true });
    let valuesGenerics = await this.listValuesGenerics();
    this.setState({ valuesGenerics, charging: false });
  }

  async listValuesGenerics() {
    let f = COURSES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  valuesGenericsChangeState = (state) => {
    this.setState({ valuesGenerics: state });
  };

  saveValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalAddCourses } = this.props;
    formulario(`api/v1.0/values/crear`, data, "post", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalAddCourses(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };


  editValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalEditCourses } = this.props;
    formulario(`api/v1.0/values/editar`, data, "put", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalEditCourses(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }

      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };

  deleteValueGeneric= async (info) =>  {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  view() {
    let { valuesGenerics } = this.state;
    let {
      showModalAddCourses,
      showModalEditCourses,
      showModalListCoursesCategories,
      showModalListCoursesModules,
    } = this.props;

    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteValueGeneric(data));
      };

      const onClickCategories = (process) => {
        this.setState({ process });
        showModalListCoursesCategories(true);
      };

      const onClickModules = (process) => {
        this.setState({ process });
        showModalListCoursesModules(true);
      };

      const onClickEdit = (process) => {
        this.setState({ process });
        showModalEditCourses(true);
      };

      let edit = (
        <BtnEdit
          color="#760068"
          callback={() => onClickEdit(rowData)}
          texto="Editar"
        />
      );
      let categories = (
        <BtnBookMark
          color="#10895D"
          callback={() => onClickCategories(rowData)}
          texto="Categorias"
        />
      );
      let modules = (
        <BtnCloud
          color="#0021C6"
          callback={() => onClickModules(rowData)}
          texto="Modulos"
        />
      );
      let delet = (
        <BtnDelete
          color="#C62300"
          callback={() => onClickDelete(rowData)}
          texto="Eliminar"
        />
      );
      return (
        <div>
          {categories}
          {modules}
          {edit}
          {delet}
        </div>
      );
    };

    return (
      <>
        <Grid style={{ paddingTop: 65, marginBottom: 80 }}>
          <ListarDatos
            id="tbl_list_generic_categories"
            datos={valuesGenerics}
            avatarImg={true}
            avatar={({ files }) => `${api}${files}`}
            acciones={(row) => actions(row)}
            fila_principal={({ name }) => name.toUpperCase()}
            filas={[
              {
                id: "date_add",
                enLista: true,
                show: ({ date_add }) =>
                  moment(date_add).format("YYYY-MM-DD, h:mm a"),
              },
              {
                id: "description",
                enLista: true,
                show: ({ description }) => description,
              },
            ]}
          />
        </Grid>
        <Fab
          style={{ zIndex: 2, position: "fixed", bottom: 50, right: 20 }}
          color="primary"
          aria-label="add"
          onClick={() => showModalAddCourses(true)}
        >
          <AddIcon />
        </Fab>
      </>
    );
  }
  render() {
    let { charging, process, valuesGenerics } = this.state;
    let {
      showModalAddCourses,
      modalAddCourses,
      showModalEditCourses,
      modalEditCourses,
      showModalListCoursesCategories,
      modalListCoursesCategories,
      showModalListCoursesModules,
      modalListCoursesModules,
      showModalAddCoursesCategories,
      modalAddCoursesCategories,
      showModalAddCoursesModules,
      modalAddCoursesModules,
      showModalAddCoursesModulesWeight,
      modalAddCoursesModulesWeight,
    } = this.props;
    return (
      <>
        <MenuTop
          label="cursos"
          valuesGenericsChangeState={this.valuesGenericsChangeState}
          valuesGenerics={valuesGenerics}
          data={COURSES}
        />
        <MenuBotton value={1} />
        {!charging ? (
          this.view()
        ) : (
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 300, maxWidth: "100%" }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        <CreateCourses
          showModalAddCourses={showModalAddCourses}
          modalAddCourses={modalAddCourses}
          saveValueGeneric={this.saveValueGeneric}
        />
        <EditCourses
          process={process}
          showModalEditCourses={showModalEditCourses}
          modalEditCourses={modalEditCourses}
          editValueGeneric={this.editValueGeneric}
        />
        <CourseCategories
          process={process}
          showModalListCoursesCategories={showModalListCoursesCategories}
          modalListCoursesCategories={modalListCoursesCategories}
          showModalAddCoursesCategories={showModalAddCoursesCategories}
          modalAddCoursesCategories={modalAddCoursesCategories}
        />
        <CourseModules
          process={process}
          showModalListCoursesModules={showModalListCoursesModules}
          modalListCoursesModules={modalListCoursesModules}
          showModalAddCoursesModules={showModalAddCoursesModules}
          modalAddCoursesModules={modalAddCoursesModules}
          showModalAddCoursesModulesWeight={showModalAddCoursesModulesWeight}
          modalAddCoursesModulesWeight={modalAddCoursesModulesWeight}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let {
    modalAddCourses,
    modalEditCourses,
    modalListCoursesCategories,
    modalAddCoursesCategories,
    modalListCoursesModules,
    modalAddCoursesModules,
    modalAddCoursesModulesWeight,
  } = state.redGenerics;
  return {
    modalAddCourses,
    modalEditCourses,
    modalListCoursesCategories,
    modalAddCoursesCategories,
    modalListCoursesModules,
    modalAddCoursesModules,
    modalAddCoursesModulesWeight,
  };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  showModalAddCourses,
  showModalEditCourses,
  showModalListCoursesCategories,
  showModalAddCoursesCategories,
  showModalListCoursesModules,
  showModalAddCoursesModules,
  showModalAddCoursesModulesWeight,
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
