import React, { useState } from "react";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { BtnForm } from "../general/ButtonAccion";
import { getValidate, Toast, validateInputs } from "../../global/js/funciones";

export default function CreateUser({
  showModalAddUser,
  modalAddUser,
  callback,
}) {
  const [charging, setCharging] = useState(false);
  const [validate, setValidate] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmit = async () => {
    let validate = await validateInputs([
      { value: email, name: "email", err: { empty: true } },
      { value: password, name: "password", err: { empty: true } },
      { value: confirmPassword, name: "confirmPassword", err: { empty: true } },
    ]);
    if (validate.length == 0) {
      setValidate([]);
      if (password !== confirmPassword) {
        Toast.fire({
          icon: "info",
          title: "Las cantraseñas no coinciden, por favor verificar.",
        });
        return;
      }
      callback({ email, password, user: email }, setCharging);
    } else {
      setValidate([]);
      setValidate(validate);
    }
  };

  const render = () => {
    return !charging ? (
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <TextField
            value={email}
            required
            type="email"
            id="email"
            name="email"
            label="Correo"
            error={!!getValidate("email", validate)}
            helperText={getValidate("email", validate)}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={password}
            required
            type="password"
            id="password"
            name="password"
            label="Contraseña"
            error={!!getValidate("password", validate)}
            helperText={getValidate("password", validate)}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={confirmPassword}
            required
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirmar Contraseña"
            error={!!getValidate("confirmPassword", validate)}
            helperText={getValidate("confirmPassword", validate)}
            fullWidth
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={modalAddUser}
        onClose={() => showModalAddUser(false)}
      >
        <AppBarModal
          title="¡ Agregar Usuario !"
          showMessageModal={() => showModalAddUser(false)}
          title_accion="Cerrar"
          accion={() => showModalAddUser(false)}
        />
        <DialogContent className="scroll">
          <Grid container>
            <Grid item xs={12} md={12}>
              {render()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm
            text="CANCELAR"
            variant="outlined"
            callback={() => showModalAddUser(false)}
          />
          <BtnForm text="GUARDAR" callback={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
}
