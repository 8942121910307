import React, { Component } from "react";
import { connect } from "react-redux";
import MenuTop from "../general/MenuTop";
import MenuBotton from "../general/MenuBotton";
import { CircularProgress, Fab, Grid } from "@material-ui/core";
import {
  showModalAddModules,
  showModalEditModules,
  showModalAddModulesMaterial,
  showModalListModulesMaterial,
  showModalAddModulesContents,
  showModalListModulesContents,
  showModalAddModulesExam,
  showModalListModulesExam,
} from "../../redux/actions/actGenerics";
import {
  consulta,
  viewError,
  formulario,
  api,
  confirmAction, 
  Toast
} from "../../global/js/funciones";
import AddIcon from "@material-ui/icons/Add";
import CreateModules from "./CreateModules";
import { MODULES } from "../general/helperGeneric";
import EditModules from "./EditModules";
import ModuleMaterial from "./ModuleMaterial";
import ModuleContents from "./ModuleContents";
import ModuleExam from "./ModuleExam";
import ListarDatos from "../general/ListarDatos";
import {
  BtnDelete,
  BtnEdit,
  BtnBookMark,
  BtnCloud,
  BtnExam,
} from "../general/ButtonAccion";
import moment from "moment";

class Modules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      valuesGenerics: [],
      process: "",
    };
  }

  async componentDidMount() {
    this.data();
  }
  async data() {
    this.setState({ charging: true });
    let valuesGenerics = await this.listValuesGenerics();
    this.setState({ valuesGenerics, charging: false });
  }

  async listValuesGenerics() {
    let f = MODULES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  valuesGenericsChangeState = (state) => {
    this.setState({ valuesGenerics: state });
  };

  saveValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalAddModules} = this.props;
    formulario(`api/v1.0/values/crear`, data, "post", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalAddModules(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };

  editValueGeneric = async (data) => {
    this.setState({ charging: true });
    let { showModalEditModules} = this.props;
    formulario(`api/v1.0/values/editar`, data, "put", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalEditModules(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      this.setState({ charging: false });
    });
  };

  deleteValueGeneric= async (info) =>  {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  view() {
    let { valuesGenerics } = this.state;
    let {
      showModalAddModules,
      showModalEditModules,
      showModalListModulesMaterial,
      showModalListModulesContents,
      showModalListModulesExam,
    } = this.props;


    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteValueGeneric(data));
      };

      const onClickMaterial = (process) => {
        this.setState({ process });
        showModalListModulesMaterial(true);
      };

      const onClickContents = (process) => {
        this.setState({ process });
        showModalListModulesContents(true);
      };

      const onClickEdit = (process) => {
        this.setState({ process });
        showModalEditModules(true);
      };

      const onClickExam = (process) => {
        this.setState({ process });
        showModalListModulesExam(true);
      };
      let edit = (
        <BtnEdit
          color="#760068"
          callback={() => onClickEdit(rowData)}
          texto="Editar"
        />
      );
      let material = (
        <BtnBookMark
          color="#10895D"
          callback={() => onClickMaterial(rowData)}
          texto="Material"
        />
      );
      let content = (
        <BtnCloud
          color="#0021C6"
          callback={() => onClickContents(rowData)}
          texto="Contenido"
        />
      );
      let exam = (
        <BtnExam
          color="#FFC300"
          callback={() => onClickExam(rowData)}
          texto="Examen"
        />
      );
      let delet = (
        <BtnDelete
          color="#C62300"
          callback={() => onClickDelete(rowData)}
          texto="Eliminar"
        />
      );
      return (
        <div>
          {material}
          {content}
          {exam}
          {edit}
          {delet}
        </div>
      );
    };

    return (
      <>
        <Grid style={{ paddingTop: 65, marginBottom: 80 }}>
          <ListarDatos
            id="tbl_list_generic_categories"
            datos={valuesGenerics}
            avatarImg={true}
            avatar={({ files }) => `${api}${files}`}
            acciones={(row) => actions(row)}
            fila_principal={({ name }) => name.toUpperCase()}
            filas={[
              {
                id: "date_add",
                enLista: true,
                show: ({ date_add }) =>
                  moment(date_add).format("YYYY-MM-DD, h:mm a"),
              },
              {
                id: "description",
                enLista: true,
                show: ({ description }) => description,
              },
            ]}
          />
        </Grid>
        <Fab
          style={{ position: "fixed", bottom: 53, right: 20, zIndex: 2 }}
          color="primary"
          aria-label="add"
          onClick={() => showModalAddModules(true)}
        >
          <AddIcon />
        </Fab>
      </>
    );
  }

  render() {
    let { charging, process, valuesGenerics } = this.state;
    let {
      showModalAddModules,
      modalAddModules,
      showModalEditModules,
      modalEditModules,
      showModalListModulesMaterial,
      modalListModulesMaterial,
      showModalAddModulesMaterial,
      modalAddModulesMaterial,
      showModalListModulesContents,
      modalListModulesContents,
      showModalAddModulesContents,
      modalAddModulesContents,
      showModalListModulesExam,
      modalListModulesExam,
      showModalAddModulesExam,
      modalAddModulesExam,
    } = this.props;

    return (
      <>
        <MenuTop
          label="modulos"
          valuesGenericsChangeState={this.valuesGenericsChangeState}
          valuesGenerics={valuesGenerics}
          data={MODULES}
        />
        <MenuBotton value={2} />
        {!charging ? (
          this.view()
        ) : (
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 300, maxWidth: "100%" }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        <CreateModules
          showModalAddModules={showModalAddModules}
          modalAddModules={modalAddModules}
          saveValueGeneric={this.saveValueGeneric}
        />
        <EditModules
          process={process}
          showModalEditModules={showModalEditModules}
          modalEditModules={modalEditModules}
          editValueGeneric={this.editValueGeneric}
        />
        <ModuleMaterial
          process={process}
          showModalListModulesMaterial={showModalListModulesMaterial}
          modalListModulesMaterial={modalListModulesMaterial}
          showModalAddModulesMaterial={showModalAddModulesMaterial}
          modalAddModulesMaterial={modalAddModulesMaterial}
          editValueGeneric={this.editValueGeneric}
        />
        <ModuleContents
          process={process}
          showModalListModulesContents={showModalListModulesContents}
          modalListModulesContents={modalListModulesContents}
          showModalAddModulesContents={showModalAddModulesContents}
          modalAddModulesContents={modalAddModulesContents}
          editValueGeneric={this.editValueGeneric}
        />
        <ModuleExam
          process={process}
          showModalListModulesExam={showModalListModulesExam}
          modalListModulesExam={modalListModulesExam}
          showModalAddModulesExam={showModalAddModulesExam}
          modalAddModulesExam={modalAddModulesExam}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let {
    modalAddModules,
    modalEditModules,
    modalAddModulesMaterial,
    modalListModulesMaterial,
    modalListModulesContents,
    modalAddModulesContents,
    modalListModulesExam,
    modalAddModulesExam,
  } = state.redGenerics;
  return {
    modalAddModules,
    modalEditModules,
    modalAddModulesMaterial,
    modalListModulesMaterial,
    modalAddModulesContents,
    modalListModulesContents,
    modalAddModulesExam,
    modalListModulesExam,
  };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  showModalAddModules,
  showModalEditModules,
  showModalAddModulesMaterial,
  showModalListModulesMaterial,
  showModalAddModulesContents,
  showModalListModulesContents,
  showModalAddModulesExam,
  showModalListModulesExam,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modules);
