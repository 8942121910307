import { makeStyles } from "@material-ui/core/styles";

export const StylesNotFound = makeStyles((theme) => ({
  boxBody: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
}));

export const StylesLogin = makeStyles((theme) => ({
  content: {
    overflow: "hidden",
    backgroundColor: "#10895D",
  },
  boxLoginImage: {
    width: "100vw",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none!important",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  boxLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    width: "100%",
    height: "380px",
    margin: "0px",
    backgroundColor: "white",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      backgroundColor: "#10895D",
    },
  },
  boxLoginContent: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      // height: "380px",
    },
  },
  boxLoginInput: {
    marginTop: "15px",
  },
  loading: {
    position: "absolute",
    left: "0",
    marginLeft: "10px",
  },
  logoDesktop: {
    display: "block",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoAppHeader: {
    display: "none",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  logoApp: {
    display: "none",
    margin: "0 auto",
    padding: "10px 0px 10px 0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
