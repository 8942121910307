// React
import React from "react";

// Material UI Components
import { IconButton, Tooltip, Button } from "@material-ui/core/";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import BookmarkAddIcon from "@material-ui/icons/BookmarkSharp";
import CloudCircleSharpIcon from "@material-ui/icons/CloudCircleSharp";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
// Material UI Icons

function BtnDetalle({ callback, color = "", texto = "Abrir", href, target }) {
  return (
    <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}>
      <IconButton
        style={{ color: color }}
        aria-label="delete"
        href={href}
        target={target}
      >
        <ImportContactsIcon fontSize="small" />{" "}
      </IconButton>
    </Tooltip>
  );
}

function BtnForm({ callback, text = "Nuevo", variant = "contained" }) {
  return (
    <Button variant={variant} color="primary" onClick={callback.bind(this)}>
      {" "}
      {text}{" "}
    </Button>
  );
}

function BtnDelete({ callback, color = "", texto = "Eliminar" }) {
  return (
    <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}>
      <IconButton style={{ color: color }} aria-label="delete">
        <DeleteIcon fontSize="small" />{" "}
      </IconButton>
    </Tooltip>
  );
}

function BtnEdit({ callback, color = '', texto = 'Editar' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><EditIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnAccion({ callback, color = '', texto = 'Acciones', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><TouchAppIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnBookMark({ callback, color = '', texto = '' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><BookmarkAddIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnCloud({ callback, color = '', texto = '' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><CloudCircleSharpIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnExam({ callback, color = '', texto = '' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><LiveHelpIcon fontSize="small" /> </IconButton></Tooltip>
}

export {
  BtnForm,
  BtnDetalle,
  BtnDelete,
  BtnEdit,
  BtnBookMark,
  BtnCloud,
  BtnExam,
  BtnAccion
  }
