import React from "react";
import Swal from "sweetalert2";

export const api = "http://3.131.24.47";

export const consulta = (
  url,
  data = null,
  method = null,
  callback,
  authorization = true
) => {
  let parametros = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (method === "patch") method = "PATCH";
  if (method) parametros.method = method;
  if (authorization)
    parametros.headers.Authorization = `JWT ${localStorage.getItem(
      "access_token"
    )}`;
  if (data) parametros.body = JSON.stringify(data);

  fetch(`${api}/${url}`, parametros)
    .then(async (response) => {
      const estado = response.status;
      if (estado === 401 && authorization) {
        window.location.href = "/admin/sin_sesion";
      } else {
        const resp = await response.json();
        callback(null, estado, resp);
      }
    })
    .catch((error) => callback(error));
};

export const viewError = (errores) => {
  for (var k in errores) {
    let r = errores[k];
    let message = "";
    r.forEach((element) => {
      message = `${message} ${element}`;
    });
    return `${k} : ${message}`;
  }
};

export const formulario = (
  url,
  data,
  method,
  callback,
  authorization = true
) => {
  let headers = {};
  if (authorization)
    headers.Authorization = `JWT ${localStorage.getItem("access_token")}`;

  fetch(`${api}/${url}`, {
    method,
    cache: "no-cache",
    mode: "cors",
    body: data,
    headers,
  })
    .then(async (response) => {
      const estado = response.status;
      if (estado === 401 && authorization) {
        window.location.href = "/admin/sin_sesion";
      } else {
        const resp = await response.json();
        callback(null, estado, resp);
      }
    })
    .catch((error) => callback(error));
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const validateInputs = (campos = []) => {
  return new Promise((resolve) => {
    let errores = [];
    campos.map(({ value, name, err }) => {
      if (err.empty && value.length == 0)
        errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
      if (err.numeric && isNaN(value))
        errores.push({ llave: name, mensaje: `Este campo debe ser un numero` });
    });

    resolve(errores);
  });
};

export const getValidate = (llave, errores) => {
  let existe = errores.find((e) => e.llave === llave);
  return existe ? existe.mensaje : "";
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const closeApp = () => {
  localStorage.setItem("access_token", "");
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("email", "");
  window.location.href = "./ingresar";
};

export const confirmAction = (callback) => {
  Swal.fire({
    title: "Estas seguro?",
    text: "Tener en cuenta que no podrás revertir esta acción",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, continuar!",
    cancelButtonText: "No, Cerrar!",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};
