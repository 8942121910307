
const MODAL_ADD_CAT = 'MODAL_ADD_CAT';
const MODAL_ADD_MOD = 'MODAL_ADD_MOD';
const MODAL_EDIT_CAT = 'MODAL_EDIT_CAT';
const MODAL_EDIT_MOD = 'MODAL_EDIT_MOD';
const MODAL_ADD_SUB = 'MODAL_ADD_SUB';
const MODAL_EDIT_SUB = 'MODAL_EDIT_SUB';
const MODAL_ADD_COURSES = 'MODAL_ADD_COURSES';
const MODAL_EDIT_COURSES = 'MODAL_EDIT_COURSES';
const MODAL_LIST_COURSES_CAT = 'MODAL_LIST_COURSES_CAT';
const MODAL_ADD_COURSES_CAT = 'MODAL_ADD_COURSES_CAT';
const MODAL_LIST_COURSES_MOD = 'MODAL_LIST_COURSES_MOD';
const MODAL_ADD_COURSES_MOD = 'MODAL_ADD_COURSES_MOD';
const MODAL_ADD_COURSES_MOD_WEIGHT = 'MODAL_ADD_COURSES_MOD_WEIGHT';
const MODAL_LIST_MODULES_MATERIAL = 'MODAL_LIST_MODULES_MATERIAL';
const MODAL_ADD_MODULES_MATERIAL = 'MODAL_ADD_MODULES_MATERIAL';
const MODAL_LIST_MODULES_CONTENTS = 'MODAL_LIST_MODULES_CONTENTS';
const MODAL_ADD_MODULES_CONTENTS = 'MODAL_ADD_MODULES_CONTENTS';
const MODAL_LIST_SUSCRIPTION_COU = 'MODAL_LIST_SUSCRIPTION_COU';
const MODAL_ADD_SUSCRIPTION_COR = 'MODAL_ADD_SUSCRIPTION_COR';
const MODAL_LIST_SUSCRIPTION_DETAIL = 'MODAL_LIST_SUSCRIPTION_DETAIL';
const MODAL_ADD_EXAM = 'MODAL_ADD_EXAM';
const MODAL_EDIT_EXAM = 'MODAL_EDIT_EXAM';
const MODAL_LIST_EXAM_QUESTION = 'MODAL_LIST_EXAM_QUESTION';
const MODAL_ADD_EXAM_QUESTION = 'MODAL_ADD_EXAM_QUESTION';
const MODAL_LIST_EXAM_ANSWER = 'MODAL_LIST_EXAM_ANSWER';
const MODAL_ADD_EXAM_ANSWER = 'MODAL_ADD_EXAM_ANSWER';
const MODAL_LIST_MODULES_EXAM = 'MODAL_LIST_MODULES_EXAM';
const MODAL_ADD_MODULES_EXAM = 'MODAL_ADD_MODULES_EXAM';

const showModalListCoursesSuscription = show => {
    return {
      type: MODAL_LIST_SUSCRIPTION_COU,
      show,
    }
  };
  const showModalListSuscriptionDetail = show => {
    return {
      type: MODAL_LIST_SUSCRIPTION_DETAIL,
      show,
    }
  };
const showModalAddCoursesSuscription = show => {
    return {
      type: MODAL_ADD_SUSCRIPTION_COR,
      show,
    }
  };
const showModalAddCategories = show => {
    return {
      type: MODAL_ADD_CAT,
      show,
    }
  };
  const showModalAddModules = show => {
    return {
      type: MODAL_ADD_MOD,
      show,
    }
  };
  const showModalEditModules = show => {
    return {
      type: MODAL_EDIT_MOD,
      show,
    }
  };
  const showModalEditCategories = show => {
    return {
      type: MODAL_EDIT_CAT,
      show,
    }
  };
  const showModalAddSubscriptions = show => {
    return {
      type: MODAL_ADD_SUB,
      show,
    }
  };
  const showModalEditSubscriptions = show => {
    return {
      type: MODAL_EDIT_SUB,
      show,
    }
  };
  const showModalAddCourses = show => {
    return {
      type: MODAL_ADD_COURSES,
      show,
    }
  };
  const showModalEditCourses = show => {
    return {
      type: MODAL_EDIT_COURSES,
      show,
    }
  };
  const showModalListCoursesCategories = show => {
    return {
      type: MODAL_LIST_COURSES_CAT,
      show,
    }
  };

  const showModalAddCoursesCategories = show => {
    return {
      type: MODAL_ADD_COURSES_CAT,
      show,
    }
  };
  const showModalListCoursesModules = show => {
    return {
      type: MODAL_LIST_COURSES_MOD,
      show,
    }
  };

  const showModalAddCoursesModules = show => {
    return {
      type: MODAL_ADD_COURSES_MOD,
      show,
    }
  };
  const showModalAddCoursesModulesWeight = show => {
    return {
      type: MODAL_ADD_COURSES_MOD_WEIGHT,
      show,
    }
  };

  const showModalListModulesMaterial = show => {
    return {
      type: MODAL_LIST_MODULES_MATERIAL,
      show,
    }
  };

  const showModalAddModulesMaterial = show => {
    return {
      type: MODAL_ADD_MODULES_MATERIAL,
      show,
    }
  };
  const showModalListModulesContents = show => {
    return {
      type: MODAL_LIST_MODULES_CONTENTS,
      show,
    }
  };

  const showModalAddModulesContents = show => {
    return {
      type: MODAL_ADD_MODULES_CONTENTS,
      show,
    }
  };
  const showModalAddExam = show => {
    return {
      type: MODAL_ADD_EXAM,
      show,
    }
  };
  const showModalEditExam = show => {
    return {
      type: MODAL_EDIT_EXAM,
      show,
    }
  };
  const showModalListExamQuestion = show => {
    return {
      type: MODAL_LIST_EXAM_QUESTION,
      show,
    }
  };

  const showModalAddExamQuestion = show => {
    return {
      type: MODAL_ADD_EXAM_QUESTION,
      show,
    }
  };
  const showModalListExamAnswer = show => {
    return {
      type: MODAL_LIST_EXAM_ANSWER,
      show,
    }
  };

  const showModalAddExamAnswer = show => {
    return {
      type: MODAL_ADD_EXAM_ANSWER,
      show,
    }
  };
  const showModalListModulesExam = show => {
    return {
      type: MODAL_LIST_MODULES_EXAM,
      show,
    }
  };

  const showModalAddModulesExam = show => {
    return {
      type: MODAL_ADD_MODULES_EXAM,
      show,
    }
  };

  
module.exports = {
    MODAL_ADD_CAT,
    showModalAddCategories,
    MODAL_ADD_MOD,
    showModalAddModules,
    MODAL_EDIT_MOD,
    showModalEditModules,
    MODAL_EDIT_CAT,
    showModalEditCategories,
    MODAL_ADD_SUB,
    showModalAddSubscriptions,
    MODAL_EDIT_SUB,
    showModalEditSubscriptions,
    MODAL_ADD_COURSES,
    showModalAddCourses,
    MODAL_EDIT_COURSES,
    showModalEditCourses,
    showModalListCoursesCategories,
    MODAL_LIST_COURSES_CAT,
    showModalAddCoursesCategories,
    MODAL_ADD_COURSES_CAT,
    showModalListCoursesModules,
    MODAL_LIST_COURSES_MOD,
    showModalAddCoursesModules,
    MODAL_ADD_COURSES_MOD,
    showModalAddCoursesModulesWeight,
    MODAL_ADD_COURSES_MOD_WEIGHT,
    showModalListModulesMaterial,
    MODAL_LIST_MODULES_MATERIAL,
    showModalAddModulesMaterial,
    MODAL_ADD_MODULES_MATERIAL,
    showModalListModulesContents,
    MODAL_LIST_MODULES_CONTENTS,
    showModalAddModulesContents,
    MODAL_ADD_MODULES_CONTENTS,
    MODAL_LIST_SUSCRIPTION_COU,
    showModalListCoursesSuscription,
    MODAL_ADD_SUSCRIPTION_COR,
    showModalAddCoursesSuscription,
    MODAL_ADD_EXAM,
    showModalAddExam,
    MODAL_EDIT_EXAM,
    showModalEditExam,
    MODAL_LIST_EXAM_QUESTION,
    showModalListExamQuestion,
    MODAL_ADD_EXAM_QUESTION,
    showModalAddExamQuestion,
    MODAL_LIST_EXAM_ANSWER,
    showModalListExamAnswer,
    MODAL_ADD_EXAM_ANSWER,
    showModalAddExamAnswer,
    showModalListModulesExam,
    MODAL_LIST_MODULES_EXAM,
    showModalAddModulesExam,
    MODAL_ADD_MODULES_EXAM,
    showModalListSuscriptionDetail,
    MODAL_LIST_SUSCRIPTION_DETAIL
  };
  