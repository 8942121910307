import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

InputFile.propTypes = {
  //variables
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function InputFile({ required = true, label, id, value }) {
  const callback = () => {
    document.getElementById(id).click();
    document.activeElement.blur();
  };
  const classes = useStyles();
  let nombre = value ? value.split("\\").pop() : "";
  nombre = nombre ? nombre : "Ningún Archivo Seleccionado";
  return (
    <div className={classes.root}>
      <TextField
        required={required}
        readOnly
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        id={`${id}_nombre`}
        label={label}
        value={nombre}
        fullWidth
        autoComplete="billing address-line1"
        onClick={callback}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={callback}
      >
        <SearchIcon />
      </IconButton>
    </div>
  );
}
