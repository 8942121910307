import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import InputFile from "../general/InputFile";
import { BtnForm } from "../general/ButtonAccion";
import { Toast } from "../../global/js/funciones";
import Information from "../general/Information";

class CreateContentsModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      names: { value: "", error: false },
      weight: { value: "", error: false },
      description: "",
      archivoGeneric: "",
      preview: "",
    };
    this.fileInput = React.createRef();
  }

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: { value: value },
    });
  };

  onChangeSimple = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  conError(valor) {
    return valor ? true : false;
  }

  onSubmit = (e) => {
    let { names, weight, archivoGeneric } = this.state;
    let archivoSend = archivoGeneric
      ? document.getElementById("archivoGeneric").files[0]
      : "";
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!archivoSend) {
      error = true;
    }
    if (!names.value) {
      names.error = obligatorio;
      error = true;
      this.setState({ names: { error: true } });
    }
    if (!weight.value) {
      weight.error = obligatorio;
      error = true;
      this.setState({ weight: { error: true } });
    }
    if (parseInt(weight.value, 10) > 100) {
      weight.error = "Esta superando el maximo permitido '100%'";
      error = true;
      this.setState({ weight: { error: true } });
    }
    if (!error) {
      this.sendData();
    } else
      Toast.fire({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
      });
  };
  sendData() {
    let { names, description, archivoGeneric, weight, preview } = this.state;
    let { showModalAddModulesContents, processContents } = this.props;

    let archivoSend = archivoGeneric
      ? document.getElementById("archivoGeneric").files[0]
      : "";
    let previewSend = preview
      ? document.getElementById("preview").files[0]
      : "";
    const data = new FormData();
    data.append("name", names.value);
    data.append("weight", weight.value);
    data.append("description", description);
    data.append("files", archivoSend);
    data.append("filesPreview", previewSend);
    data.append("principal", processContents);
    data.append("type_files", 2);

    this.props.saveValueGenericFile(data, () =>
      this.setState({ charging: false })
    );
    this.reset();
    showModalAddModulesContents(false);
  }

  reset() {
    this.setState({
      names: { value: "", error: false },
      weight: { value: "", error: false },
      description: "",
      archivoGeneric: "",
    });
  }

  getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  view() {
    let { names, description, charging, archivoGeneric, weight, preview } =
      this.state;
    let previewEnable = false;
    let extension = this.getFileExtension(archivoGeneric);
    if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "flv" ||
      extension === "3gp" ||
      extension === "mov" ||
      extension === "webm"
    ) {
      previewEnable = true;
    }
    return !charging ? (
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <TextField
            value={names.value}
            required
            type="text"
            id="names"
            name="names"
            label="Nombre"
            error={this.conError(names.error)}
            helperText={names.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={weight.value}
            required
            type="text"
            id="weight"
            name="weight"
            label="Peso"
            error={this.conError(weight.error)}
            helperText={weight.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={description}
            type="text"
            id="description"
            name="description"
            label="Descripción"
            fullWidth
            onChange={this.onChangeSimple}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="oculto"
            ref={this.fileInput}
            type="file"
            id="archivoGeneric"
            name="archivoGeneric"
            label="Archivo"
            fullWidth
            autoComplete="billing address-line1"
            onChange={this.onChangeSimple}
          />
          <Grid item xs={12}>
            <InputFile
              label="Archivo"
              id="archivoGeneric"
              value={archivoGeneric}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="oculto"
            ref={this.fileInput}
            type="file"
            id="preview"
            name="preview"
            label="Imagen Previa del Video"
            fullWidth
            autoComplete="billing address-line1"
            onChange={this.onChangeSimple}
          />
          {previewEnable && (
            <>
              <Grid item xs={12}>
                <InputFile
                  label="Imagen Previa del Video"
                  id="preview"
                  value={preview}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Information
          title="Importante"
          description="Se recomienda que las imágenes cargadas tengan un ancho y largo  entre los 800px y los 1500px."
          type="info"
        />
      </Grid>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let { showModalAddModulesContents, modalAddModulesContents } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalAddModulesContents}
          onClose={() => showModalAddModulesContents(false)}
        >
          <AppBarModal
            title={" ¡ Agregar Contenido ! "}
            showMessageModal={() => showModalAddModulesContents(false)}
            title_accion="Cerrar"
            accion={() => showModalAddModulesContents(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddModulesContents(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
CreateContentsModules.propTypes = {
  modalAddModulesContents: PropTypes.bool.isRequired,
};

export default CreateContentsModules;
