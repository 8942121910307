import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TextField from "@mui/material/TextField";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Badge,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemAvatar,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  stylesTemplate1,
  AppBar,
  DrawerHeader,
  Main,
  stylesTemplate1SectionMain,
  CircularProgressWithLabel,
  StyleCircularProgressForModals,
  StyleForClientContentModul,
  StylesForModalScore,
  Demo,
  stylesTemplate1ClientHome,
} from "../../global/css/components/StylesTemplate1";
import {
  getClientCourse,
  getModulsOfSubs,
  getClientCourseProgress,
  getContentOfModule,
  getQuestionsAndAnswers,
} from "../../global/js/Requests";
import { Loading, LoadingClientMenu } from "./Template1Loadings";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { consulta, api, getWindowDimensions } from "../../global/js/funciones";
import { Player } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import styled from "@emotion/styled";
import { AppBarModalTemplate1 } from "./Template1AppBarsModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Rating from "@mui/material/Rating";
import { ModalMyAccount } from "./Template1Headers";
import CommentIcon from "@mui/icons-material/Comment";
import { EXAM } from "../general/helperGeneric";
import { updateMessage } from "../../redux/actions/actGlobal";
import { useDispatch } from "react-redux";
import ListarDatos from "../general/ListarDatos";
import EditIcon from "@mui/icons-material/Edit";
import examImage from "../../global/images/exam.svg";
import {
  confirmAction,
} from "../../global/js/funciones";

export default function ClientMenu(props) {
  const theme = useTheme();
  const classes = stylesTemplate1();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [mainInformation, setMainInformation] = React.useState({});

  const [nextClass, setNextClass] = React.useState("");
  const [lastClass, setLasClass] = React.useState("");

  const [currentPositionClass, setCurrentPositionClass] = React.useState("");
  const [currentPositionModule, setCurrentPositionModule] = React.useState("");

  const [firstMount, setFirstMount] = React.useState(false);

  const [progress, setProgress] = React.useState(10);

  const [coursesClient, setCoursesClient] = React.useState({});
  const [progressOfClient, setProgressOfClient] = React.useState([]);

  const [moduls, setModuls] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [idForContent, setIdForContent] = React.useState("");
  const [openModalContentModul, setOpenModalContentModul] =
    React.useState(false);
  const [moduleSelected, setModuleSelected] = React.useState([]);
  const [finish, setFinish] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [openModalScore, setOpenModalScore] = React.useState(false);
  const [filesFilters, setFilesFilters] = React.useState([]);
  const [valueOfStars, setValueOfStars] = React.useState(0);
  const [openModalExams, setOpenModalExams] = React.useState(false);
  const [modulsWithExams, setModulsWithExams] = React.useState([]);
  const [openModalFormExam, setOpenModalFormExam] = React.useState(false);
  const [examOfModul, setExamOfModul] = React.useState([]);
  const [modulSelectedInExam, setModulSelectedInExam] = React.useState([]);
  const tool = Boolean(anchorEl);
  const [openModalAccount, setOpenModalAccount] = React.useState(false);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [clickNotifications, setClickNotifications] = React.useState(false);
  const [view, setView] = React.useState("Course");

  React.useEffect(() => {
    setLoading(true);
    getInitInformation();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getInitInformation = async () => {
    
    let data = [];

    data = await getModulsOfSubs(props.Course.principal.id);

    setModuls(data.moduls);
    setFiles(data.files);
    setClickNotifications(false);
    let dataCoursesClient = await getClientCourse(props.Course.principal.id);
    if (dataCoursesClient == null) {
    } else {
      setCoursesClient(dataCoursesClient);
      setView("Course");
      let dataProgress = await getClientCourseProgress(dataCoursesClient.id);
      let cont = 0;
      let swIfFirstTime = true;
      dataProgress.contenido.map((progress, index) => {
        data.files.map((file) => {
          if (progress.id_content == file.id) {
            file.sw = true;
            cont = cont + 1;
            if (dataProgress.contenido.length == index + 1) {
              file.current = true;
              swIfFirstTime = false;
              setMainInformation(file);
              setNextClass(data.files[index + 1]);
              setLasClass(data.files[index - 1]);

              setCurrentPositionModule(index);
              setCurrentPositionClass(index);
            }
          }
        });
      });
      if (data.files.length > 0) {
        if (swIfFirstTime) {
          setMainInformation(data.files[0]);
          setNextClass(data.files[0 + 1]);
          setLasClass(data.files[0 - 1]);

          setCurrentPositionModule(0);
          setCurrentPositionClass(0);
        }
      }
      setFinish(cont);
      setTotal(data.files.length);
      setProgressOfClient(dataProgress.contenido);
      
      let result = calculateProgressBar(
        data.moduls,
        data.files,
        false,
        "first_mount"
      );
      if (result.moduls_finish.length > 0) {
        // Validar si esos modulos tienen examenes, si el array que viene tiene mas de 0 elementos, entonces tengo que mostrar el modal.
        let modulsVerify = await verifyExams(result.moduls_finish);
        if (modulsVerify.moduls.length > 0) {
          setModulsWithExams(modulsVerify.moduls);
          setOpenModalExams(true);
        }
      }
      setProgress(Math.trunc(result.percentage));
    }
    setLoading(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const desmountSection = () => {
    props.setView("Client");
  };

  const calculateProgressBar = (moduls, files, first, id) => {
    let weightFiles = 0;
    let percentageOfFiles = 0;
    let percentageOfModul = 0;
    let percentageOfCourse = 0;
    let result = 0;
    let moduls_finish = [];
    let if_finish = false;
    moduls.map((modul, index) => {
      weightFiles = 0;
      files.map((file) => {
        if (modul.principal.id == file.principal && file.sw == true) {
          weightFiles = weightFiles + parseInt(file.weight);
        }
      });

      if (weightFiles == 100 && id == modul.principal.id) {
        moduls_finish.push(modul);
        if_finish = true;
      } else if (weightFiles == 100) {
        moduls_finish.push(modul);
      }

      percentageOfFiles = weightFiles / 100;
      percentageOfModul = modul.weight / 100;

      percentageOfCourse =
        percentageOfCourse + percentageOfModul * percentageOfFiles;
      result = percentageOfCourse * 100;
    });

    return {
      percentage: result,
      if_finish: if_finish,
      moduls_finish: moduls_finish,
    };
  };

  const handleRegisterClientCourse = async () => {
    setLoading(true);
    let url = `api/v1.0/process/crear`;
    let data = {
      id_user: localStorage.getItem("idOfClient"),
      id_course: props.Course.principal.id,
    };

    const callback = (error, estado, resp) => {
      if (!error) {
        if (estado === 200) {
          setView("Course");
        }
      }

      setLoading(false);
    };

    await consulta(url, data, "POST", callback, false);
  };

  function getInfo(file, index) {
    setMainInformation(file);

    setNextClass(files[index + 1]);
    setLasClass(files[index - 1]);

    setCurrentPositionModule(index);
    setCurrentPositionClass(index);
    if (windowDimensions.width < 960) {
      setOpen(false);
    }

    setFirstMount(false);
  }

  const handleChangeClass = (file, index) => {
    getInfo(file, index);
  };

  function getNextClass() {
    let newPosition = currentPositionClass + 1;

    setMainInformation(files[newPosition]);
    setNextClass(files[newPosition + 1]);
    setLasClass(files[newPosition - 1]);

    setCurrentPositionModule(newPosition);
    setCurrentPositionClass(newPosition);
  }

  function getPreviousClass() {
    let newPosition = currentPositionClass - 1;
    setMainInformation(files[newPosition]);
    setNextClass(files[newPosition + 1]);
    setLasClass(files[newPosition - 1]);

    setCurrentPositionModule(newPosition);
    setCurrentPositionClass(newPosition);
  }

  function handleContentModule(module) {
    setIdForContent(module.id);
    setModuleSelected(module);
    setOpen(false);
    setOpenModalContentModul(true);
  }

  const nextChangeClass = () => {
    getNextClass();
  };

  const backChangeClass = () => {
    getPreviousClass();
  };

  const finishClass = async () => {
    setOpenModalScore(false);
    setLoading(true);

    let url = `api/v1.0/process/crearProcess`;
    let data = {
      id_courses: coursesClient.id,
      id_content: mainInformation.id,
      score: valueOfStars.toString(),
    };
    const callback = async (error, estado, resp) => {
      let tipo = "info";

      if (!error) {
        if (estado === 200) {
          tipo = "success";
          let archivos = files;
          let dataProgress = await getClientCourseProgress(coursesClient.id);
          dataProgress.contenido.map((progress) => {
            archivos.map((archivo) => {
              if (progress.id_content == archivo.id) {
                archivo.sw = true;
              }
            });
          });
          setFiles(archivos);
          setClickNotifications(false)
          let result = calculateProgressBar(
            moduls,
            archivos,
            false,
            mainInformation.principal
          );
          if (result.if_finish == true) {
            // setOpenModalExams(true)
            // valida der array de modulos terminados si tienen o no examenes, si vienen mas de 0 es porque tienen examenes y se habre el modal.
            let modulsVerify = await verifyExams(result.moduls_finish);
            if (modulsVerify.moduls.length > 0) {
              setModulsWithExams(modulsVerify.moduls);
              setOpenModalExams(true);
            }
          }
          setProgress(Math.trunc(result.percentage));
        }
      }

      setLoading(false);
    };

    const msg = consulta(url, data, "POST", callback, false);
  };

  const verifyExams = async (moduls) => {
    let url = `api/v1.0/process/validateExams`;
    let data = {
      moduls: moduls,
      pk: EXAM,
      course: props.Course.principal.id,
      id_client: localStorage.getItem("idOfClient"),
    };

    return new Promise((resolve) => {
      consulta(
        url,
        data,
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        },
        false
      );
    });
  };

  const handleOpenModalScore = () => {
    if (windowDimensions.width < 960) {
      setOpen(false);
    }
    setOpenModalScore(true);
  };

  const handleBackToLastCLass = () => {
    files.map((e, index) => {
      if (e.current == true) {
        setMainInformation(e);
        setNextClass(files[index + 1]);
        setLasClass(files[index - 1]);

        setCurrentPositionModule(index);
        setCurrentPositionClass(index);
        setFirstMount(false);
      }
    });
  };

  const handleKeyPress = async (event) => {
    let inputValue = document.getElementById("search").value;
    if (inputValue !== "") {
      let fileFilters = files.filter((e) => {
        if (
          e.name.toString().toLowerCase().includes(inputValue.toLowerCase())
        ) {
          return e;
        }
      });
      setFilesFilters(fileFilters);
    } else {
      setFilesFilters([]);
    }
  };

  const handleOpenModalFormExam = (modul, modulComplete) => {
    setExamOfModul(modul);
    setModulSelectedInExam(modulComplete);
    setOpenModalFormExam(true);
  };

  const sendResetCourse = async () => {
    let url = `api/v1.0/process/reset`;
    let data = {
      id_course: props.Course.principal.id,
      id_client: localStorage.getItem("idOfClient")
    };
    return new Promise((resolve) => {
      consulta(
        url,
        data,
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? {resp, error, estado} : { resp: null, status: estado });
        }
      );
    });
  }

  const eventResetCourse = () => {
    confirmAction(handleResetCourse);
  }

  const handleResetCourse = async () => {
    setOpenModalExams(false)
    setLoading(true)

    let resp = await sendResetCourse();
    if(resp.estado == 200){
      let respPostReset = await postResetCourse();
      if(respPostReset.status == 200){
        setModulsWithExams([])
        getInitInformation()
      }else{
        setLoading(false)
      }
    }else{
      setLoading(false)
    }
    
  }
  const postResetCourse = async () => {
    let url = `api/v1.0/process/crear`;
    let data = {
      id_user: localStorage.getItem("idOfClient"),
      id_course: props.Course.principal.id,
    };
    return new Promise((resolve) => {
      consulta(url, data, "POST", (error, estado, resp) => {
        resolve(estado === 200 && !error ? { resp, status: estado } : null);
      });
    });
  }
  const viewToRender = () => {
    switch (view) {
      case "Course":
        return (
          <>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar position="fixed" open={open}>
                <Toolbar
                  sx={{
                    justifyContent: open == true ? "flex-end" : "space-between",
                  }}
                  className={classes.ToolBarStyle}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: "none" }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Tooltip title="Atras">
                      <IconButton onClick={() => desmountSection()}>
                        <ArrowBackIcon className={classes.ColorWhiteIcons} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Notificaciones">
                      <IconButton onClick={() => {
                        setClickNotifications(true)
                        setOpenModalExams(true)
                      }}>
                        <Badge
                          badgeContent={modulsWithExams.length}
                          color="primary"
                        >
                          <NotificationsActiveIcon
                            className={classes.ColorWhiteIcons}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>

                    <React.Fragment>
                      <Tooltip title="Mi cuenta">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={tool ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={tool ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={tool}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: classes.MenuStyles,
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={() => setOpenModalAccount(true)}>
                          <ListItemIcon>
                            <Settings fontSize="small" />
                          </ListItemIcon>
                          Mi Cuenta
                        </MenuItem>
                        {/* <MenuItem>
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Salir
                        </MenuItem> */}
                      </Menu>
                    </React.Fragment>
                  </Box>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.DrawerStyles}
                variant={
                  windowDimensions.width < 960 ? "temporary" : "persistent"
                }
                anchor="left"
                open={open}
              >
                <DrawerHeader className={classes.DrawerHeaderStyles}>
                  <Typography variant="h6" noWrap component="div">
                    {props.Course.principal.name}
                  </Typography>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon className={classes.ColorWhiteIcons} />
                    ) : (
                      <ChevronRightIcon className={classes.ColorWhiteIcons} />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                {loading == true ? (
                  <LoadingClientMenu />
                ) : (
                  <>
                    <Box className={classes.DrawerListStyles}>
                      <CircularProgressWithLabel
                        value={progress}
                        className={classes.DrawerProgress}
                      />
                      <Box sx={{ width: "200px" }}>
                        <CssTextField
                          id="search"
                          autoComplete="off"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={"Buscar Lección"}
                          variant="outlined"
                          onKeyDown={(e) => handleKeyPress(e)}
                        />
                      </Box>
                      <Typography className={classes.textOfDescriptionCourse}>
                        {`${finish} de ${total} lecciones terminadas`}
                      </Typography>
                    </Box>
                    <List>
                      {filesFilters.length > 0
                        ? filesFilters.map((file, index) => {
                            return (
                              <>
                                <Divider />
                                <ListItem
                                  button
                                  key={index}
                                  onClick={() => handleChangeClass(file, index)}
                                >
                                  <ListItemIcon>
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          file.sw == false
                                            ? "#c3c0bc"
                                            : "#3498db",
                                      }}
                                      className={classes.ListItemIconStyles}
                                    >
                                      {file.cont}
                                    </Box>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.ListItemTextFont}
                                  >
                                    <Typography
                                      className={classes.ListItemTypographyFont}
                                    >
                                      {file.name}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                                <Divider />
                              </>
                            );
                          })
                        : moduls.map((e, indexUp) => (
                            <>
                              <Divider />
                              <ListItem
                                className={classes.PointerOfModuleSection}
                                key={indexUp}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    onClick={() =>
                                      handleContentModule(e.principal)
                                    }
                                  >
                                    <CommentIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemIcon>
                                  <ViewModuleIcon
                                    className={classes.BlueColor}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={e.principal.name} />
                              </ListItem>
                              <Divider />
                              {files.map((file, index) => {
                                if (file.principal == e.principal.id) {
                                  return (
                                    <ListItem
                                      button
                                      key={index}
                                      onClick={() =>
                                        handleChangeClass(file, index)
                                      }
                                    >
                                      <ListItemIcon>
                                        <Box
                                          sx={{
                                            backgroundColor:
                                              file.sw == false
                                                ? "#c3c0bc"
                                                : "#3498db",
                                          }}
                                          className={classes.ListItemIconStyles}
                                        >
                                          {file.cont}
                                        </Box>
                                      </ListItemIcon>
                                      <ListItemText
                                        className={classes.ListItemTextFont}
                                      >
                                        <Typography
                                          className={
                                            classes.ListItemTypographyFont
                                          }
                                        >
                                          {file.name}
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  );
                                }
                              })}
                            </>
                          ))}
                    </List>
                  </>
                )}
              </Drawer>
              <Main
                open={open}
                sx={{ padding: "0" }}
                className={classes.MainDeleteMarginLeft}
              >
                <DrawerHeader />
                {loading == true ? (
                  <LoadingClientMenu />
                ) : firstMount == true ? (
                  <ClientMenuSectionMainFirstMount
                    mainInformation={mainInformation}
                    nextClass={nextClass}
                    lastClass={lastClass}
                    setMainInformation={setMainInformation}
                    setNextClass={setNextClass}
                    setLasClass={setLasClass}
                    progressOfClient={progressOfClient}
                    handleBackToLastCLass={handleBackToLastCLass}
                  />
                ) : (
                  <ClientMenuSectionMain
                    mainInformation={mainInformation}
                    nextClass={nextClass}
                    lastClass={lastClass}
                    nextChangeClass={nextChangeClass}
                    backChangeClass={backChangeClass}
                    handleOpenModalScore={handleOpenModalScore}
                  />
                )}
              </Main>
            </Box>

            {openModalContentModul == true ? (
              <ClientContentModul
                setOpenModal={setOpenModalContentModul}
                openModal={openModalContentModul}
                moduleId={idForContent}
                moduleSelected={moduleSelected}
                setModuleSelected={setModuleSelected}
                drawerOpen={open}
                setDrawerOpen={setOpen}
              />
            ) : (
              ""
            )}

            {openModalScore == true ? (
              <ModalScoreForClass
                openModal={openModalScore}
                setOpenModal={setOpenModalScore}
                value={valueOfStars}
                setValue={setValueOfStars}
                finishClass={finishClass}
              />
            ) : (
              ""
            )}

            {openModalAccount == true ? (
              <ModalMyAccount
                open={openModalAccount}
                setOpen={setOpenModalAccount}
              />
            ) : (
              ""
            )}
            {openModalExams == true ? (
              <ModalForExams
                openModal={openModalExams}
                setOpenModal={setOpenModalExams}
                modulsWithExams={modulsWithExams}
                setModulsWithExams={setModulsWithExams}
                handleOpenModalFormExam={handleOpenModalFormExam}

                coursesClient={coursesClient}
                setCoursesClient={setCoursesClient}
                handleResetCourse={handleResetCourse}

                eventResetCourse={eventResetCourse}

                calculateProgressBar={calculateProgressBar}
                moduls={moduls}
                files={files}
                mainInformation={mainInformation}
                verifyExams={verifyExams}

                notifications={clickNotifications}
                Course={props.Course}
              />
            ) : (
              ""
            )}
            {openModalFormExam == true ? (
              <ModalFormExam
                openModal={openModalFormExam}
                setOpenModal={setOpenModalFormExam}
                setOpenModalExams={setOpenModalExams}
                examOfModul={examOfModul}
                setExamOfModul={setExamOfModul}
                Course={props.Course}
                modulSelectedInExam={modulSelectedInExam}

                modulsWithExams={modulsWithExams}
              />
            ) : (
              ""
            )}
          </>
        );
      case "Introduction":
        return (
          <React.Fragment>
            {loading == true ? (
              <Loading />
            ) : (
              <ClientMenuSectionPresentationOfCourse
                title={props.Course.principal.name}
                description={props.Course.principal.description}
                moduls={moduls}
                files={files}
                handleRegisterClientCourse={handleRegisterClientCourse}
              />
            )}
          </React.Fragment>
        );
    }
  };

  return <React.Fragment>{viewToRender()}</React.Fragment>;
}

const ClientMenuSectionMain = (props) => {
  const classes = stylesTemplate1();
  const classesSecionMain = stylesTemplate1SectionMain();

  return (
    <Box className={classesSecionMain.BackgroundSectionMain}>
      <Box className={classesSecionMain.FirstSectionMain}>
        <Box className={classesSecionMain.FirstSectionMainLeft}>
          <Box className={classes.expandLeftPlace}>
            <Player
              playsInline
              poster={`${api}${props.mainInformation.filesPreview}`}
              src={`${api}${props.mainInformation.files}`}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flexDirection: !props.mainInformation.sw ? "row" : "row-reverse",
          }}
          className={classesSecionMain.FirstSectionTittleLeft}
        >
          {!props.mainInformation.sw && (
            <Box sx={{ width: "50%" }}>
              <Button
                disabled={props.mainInformation.sw == true ? true : false}
                variant="contained"
                sx={{ backgroundColor: "#3498db", color: "#ffffff!important" }}
                onClick={() => props.handleOpenModalScore()}
              >
                Finalizar
              </Button>
            </Box>
          )}
          <Box className={classesSecionMain.FirstSecionMainRightButtons}>
            <Box className={classesSecionMain.FirstSecionMainRightButtonsBack}>
              <Tooltip
                title={
                  typeof props.lastClass == "undefined"
                    ? ""
                    : `${props.lastClass.name}`
                }
              >
                <IconButton
                  onClick={() => props.backChangeClass()}
                  disabled={
                    typeof props.lastClass == "undefined" ? true : false
                  }
                >
                  <ArrowBackIcon sx={{ color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classesSecionMain.FirstSecionMainRightButtonsNext}>
              <Tooltip
                title={
                  typeof props.nextClass == "undefined"
                    ? ""
                    : `${props.nextClass.name}`
                }
              >
                <IconButton
                  onClick={() => props.nextChangeClass()}
                  disabled={
                    typeof props.nextClass == "undefined" ? true : false
                  }
                >
                  <ArrowForwardIcon sx={{ color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              width: !props.mainInformation.sw ? "100%" : "50%",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                lineHeight: "2.25rem",
                color: "#3498db",
              }}
              className={classes.ReduceFontSizeOfTittle}
            >
              {props.mainInformation.name}
            </Typography>
          </Box>
        </Box>
        <Box className={classesSecionMain.FirstSectionMainRightMobile}>
          <Box sx={{ width: "50%" }}>
            <Button
              disabled={props.mainInformation.sw == true ? true : false}
              variant="contained"
              sx={{ backgroundColor: "#3498db", marginTop: "5px" }}
              onClick={() => props.handleOpenModalScore()}
            >
              Finalizar
            </Button>
          </Box>
          <Box
            className={classesSecionMain.FirstSecionMainRightButtonsMobileBox}
          >
            <Box
              className={classesSecionMain.FirstSecionMainRightButtonsMobile}
            >
              <Box
                className={
                  classesSecionMain.FirstSecionMainRightButtonsBackMobile
                }
              >
                <Tooltip
                  title={
                    typeof props.lastClass == "undefined"
                      ? ""
                      : `${props.lastClass.name}`
                  }
                >
                  <IconButton
                    onClick={() => props.backChangeClass()}
                    disabled={
                      typeof props.lastClass == "undefined" ? true : false
                    }
                  >
                    <ArrowBackIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                className={
                  classesSecionMain.FirstSecionMainRightButtonsNextMobile
                }
              >
                <Tooltip
                  title={
                    typeof props.nextClass == "undefined"
                      ? ""
                      : `${props.nextClass.name}`
                  }
                >
                  <IconButton
                    onClick={() => props.nextChangeClass()}
                    disabled={
                      typeof props.nextClass == "undefined" ? true : false
                    }
                  >
                    <ArrowForwardIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "70%",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                lineHeight: "2.25rem",
                color: "#3498db",
              }}
              className={classes.ReduceFontSizeOfTittle}
            >
              {props.mainInformation.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classesSecionMain.Description}>
        <Typography
          className={classesSecionMain.SectionPresentationText}
          sx={{ fontSize: "1.8rem!important" }}
        >
          Descripción de la Clase:
        </Typography>
        {props.mainInformation.description}
      </Box>
    </Box>
  );
};

const ModalForExams = (props) => {
  const classes = stylesTemplate1SectionMain();
  const classesBlue = stylesTemplate1ClientHome();
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    const beforeOpenExams = async () => {
      let result = props.calculateProgressBar(
        props.moduls,
        props.files,
        false,
        "first_mount"
      );
      console.log(result)
      if (result.moduls_finish.length > 0) {
        // setOpenModalExams(true)
        // valida der array de modulos terminados si tienen o no examenes, si vienen mas de 0 es porque tienen examenes y se habre el modal.
        let modulsVerify = await props.verifyExams(result.moduls_finish);
        if (modulsVerify.moduls.length > 0) {
          props.setModulsWithExams(modulsVerify.moduls);
        }
      }
      let dataCoursesClient = await getClientCourse(props.Course.principal.id);
      if (dataCoursesClient !== null) {
        props.setCoursesClient(dataCoursesClient);
      }
      setLoading(false)
    }
    if(props.notifications == true){
      beforeOpenExams()
    }else{
      setLoading(false)
    }
    
  },[])
  const SearchExam = (exam, modul) => {
    if (modul.is_exam_finish == false) {
      props.handleOpenModalFormExam(exam, modul);
    }
  };
  const Titles = (modul) => {
    if (modul.is_exam_finish == false) {
      return `${modul.principal.name}`;
    } else {
      return `${modul.principal.name} - Calificacion: ${modul.exam_finish.score}`;
    }
  };

  const actions = (rowData) => {
    let edit = (
      <Tooltip title="Realizar Examen">
        <IconButton onClick={() => SearchExam(rowData.exams[0], rowData)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
    return rowData.is_exam_finish == true ? (
      <div>
        <b>{rowData.exam_finish.score}</b>
      </div>
    ) : (
      <div>{edit}</div>
    );
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <AppBarModalTemplate1
        title={"¡Tus Exámenes!"}
        title_accion="Cerrar"
        accion={() => props.setOpenModal(false)}
      />
      <DialogContent className="scroll">
        {loading == true ? <LoadingClientMenu />: 
        <React.Fragment>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            className={classes.content}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.boxLoginImage}
            >
              <img src={examImage} alt="Logo examen" width={150} height={150} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              className={classes.boxLoginImage}
            >
              {props.coursesClient.finish_score != null && parseFloat(props.coursesClient.finish_score) >= 3.5  ?
              <Typography sx={{ mt: 2, mb: 2, color: '#3498db' }} variant="h6" component="div">
                ¡Has APROBADO el Curso! Tu Calificación es: {props.coursesClient.finish_score}
              </Typography> : props.coursesClient.finish_score != null && parseFloat(props.coursesClient.finish_score) < 3.5 ? 
              <Typography sx={{ mt: 2, mb: 2, color: '#3498db' }} variant="h6" component="div">
                NO has APROBADO el Curso, pero puedes volverlo a intentar <Button variant="contained" sx={{ backgroundColor: "#3498db" }} onClick={() => props.eventResetCourse()}>Empezar</Button>
              </Typography> : <Typography
                sx={{ fontSize: "15px", color: "#3498db", textAlign: "center" }}
              >
                Hola, recuerda que debes realizar todos los exámenes para
                aprobar el curso.
              </Typography>}
            </Grid>
          </Grid>
          
          <ListarDatos
            id="tbl_list_generic_categories"
            datos={props.modulsWithExams}
            avatar={({ exams }) => `${exams[0].principal.name.charAt(0)}`}
            color_avatar={({ is_exam_finish }) => ({
              backgroundColor: is_exam_finish == true ? "#0b962e" : "#d0022a",
              color: "#fff",
            })}
            acciones={(row) => actions(row)}
            fila_principal={({ exams }) =>
              exams[0].principal.name.toUpperCase()
            }
            filas={[
              {
                id: "Nombre del Examen",
                enLista: false,
                show: ({ exams }) => exams[0].principal.name,
              },
              {
                id: "estado",
                enLista: true,
                show: ({ is_exam_finish }) =>
                  `estado : ${is_exam_finish ? "Realizado" : "Pendiente"}`,
              },
            ]}
          />
        </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classesBlue.BlueColor}
          onClick={() => props.setOpenModal(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ModalFormExam = (props) => {
  const classesBlue = stylesTemplate1ClientHome();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [questionsAnswers, setQuestionsAnswers] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const getInitInformation = async () => {
      let questionsAndAnwers = await getQuestionsAndAnswers(
        props.examOfModul.principal.id
      );
      setQuestionsAnswers(questionsAndAnwers);
      setLoading(false);
    };
    getInitInformation();
  }, []);

  const sendData = async () => {
    let url = `api/v1.0/process/exam/validate`;
    let data = {
      resp: checked,
      id_course: props.Course.principal.id,
      id_exam: props.examOfModul.principal.id,
      id_modul: props.modulSelectedInExam.principal.id,
      id_client: localStorage.getItem("idOfClient"),
      amount_of_exams: props.modulsWithExams.length
    };
    return new Promise((resolve) => {
      consulta(
        url,
        data,
        "POST",
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        },
        false
      );
    });
  };

  const handleSendExam = async () => {
    let validateCheck = checked;
    if (validateCheck.length > 0) {
      if (validateCheck.length == questionsAnswers.length) {
        let sw = true;
        validateCheck.map((obj) => {
          if (obj.answer.length == 0) {
            sw = false;
          }
        });
        if (sw == true) {
          setLoading(true);
          let resp = await sendData();
          if (resp != null) {
            props.setOpenModal(false);
            props.setOpenModalExams(false);
            dispatch(
              updateMessage({
                title: "Respuestas Guardadas.",
                types: "success",
                showMessage: true,
                time: 6000,
              })
            );
          }
        } else {
          dispatch(
            updateMessage({
              title: "FALTAN PREGUNTAS POR CONTESTAR",
              types: "info",
              showMessage: true,
              time: 6000,
            })
          );
        }
      } else {
        dispatch(
          updateMessage({
            title: "FALTAN PREGUNTAS POR CONTESTAR",
            types: "info",
            showMessage: true,
            time: 6000,
          })
        );
      }
    } else {
      dispatch(
        updateMessage({
          title: "FALTAN PREGUNTAS POR CONTESTAR",
          types: "info",
          showMessage: true,
          time: 6000,
        })
      );
    }
  };

  const onChange = (question, answer) => {
    let newChecked = [...checked];
    const currentIndex = checked
      .map(function (e) {
        return e.question.id;
      })
      .indexOf(question.id);
    const currentIndexAnswer = checked.map(function (e) {
      return e.answer
        .map(function (e) {
          return e.id;
        })
        .indexOf(answer.id);
    });
    if (currentIndex == -1) {
      newChecked.push({ question: question, answer: [answer] });
    } else if (currentIndexAnswer[currentIndex] == -1) {
      newChecked[currentIndex].answer.push(answer);
    } else if (currentIndexAnswer[currentIndex] !== -1) {
      newChecked[currentIndex].answer.splice(
        currentIndexAnswer[currentIndex],
        1
      );
    }
    setChecked(newChecked);
  };

  const onChangeSoloQuestion = (question, answer) => {
    let newChecked = [...checked]
    const currentIndex = checked.map(function(e) { return e.question.id; }).indexOf(question.id);
    const currentIndexAnswer = checked.map(function(e) { return e.answer.map(function(e) {return e.id}).indexOf(answer.id); });
    if(currentIndex == -1){
      newChecked.push({question: question, answer: [answer]})
      console.log("NO EXISTE PREGUNTA")
    }else if(currentIndexAnswer[currentIndex] == -1 && newChecked[currentIndex].answer.length > 0){
      newChecked[currentIndex].answer = []
      newChecked[currentIndex].answer.push(answer)
      console.log("NO EXISTE RESPUESTA")
    }
    setChecked(newChecked)
    console.log(newChecked)
    console.log(currentIndexAnswer)
    console.log(currentIndex)
  }

  const is_check = (question, answer) => {
    let newChecked = [...checked]
    const currentIndex = checked.map(function(e) { return e.question.id; }).indexOf(question.id);
    const currentIndexAnswer = checked.map(function(e) { return e.answer.map(function(e) {return e.id}).indexOf(answer.id); });
    if(currentIndex == -1){
      return false
    }else if(currentIndexAnswer[currentIndex] == -1){
      return false
    }else if(currentIndexAnswer[currentIndex] !== -1){
      return true
    }
  }

  const titles = (obj) => {
    if(obj.principal.type_data == "2"){
      return `${obj.principal.name} (Pregunta Unica)`
    }else{
      return `${obj.principal.name} (Pregunta Multiple)`
    }
  }

  return(
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <AppBarModalTemplate1
        title={"¡Conteste Todas las Preguntas!"}
        title_accion="Cerrar"
        accion={() => props.setOpenModal(false)}
      />
      <DialogContent className="scroll">
        {loading == true ? <LoadingClientMenu /> :
        <React.Fragment>
          {questionsAnswers.map((obj) => (
            <Grid item xs={12}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                {titles(obj)}
              </Typography>
              <Demo>
                <List dense={dense}>

                  {obj.principal.type_data == "2" ?
                    obj.answers.map((answer) => (
                      <ListItem
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <Checkbox
                              checked={is_check(obj, answer)}
                              onChange={() => onChangeSoloQuestion(obj, answer)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={`${answer.principal.name}`}
                        />
                      </ListItem>
                    ))
                  :
                    obj.answers.map((answer) => (
                      <ListItem
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <Checkbox
                              onChange={() => onChange(obj, answer)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={`${answer.principal.name}`}
                        />
                      </ListItem>
                    ))}
                </List>
              </Demo>
            </Grid>
          ))}  
        </React.Fragment>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classesBlue.BlueColor}
          onClick={() => handleSendExam()}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ClientMenuSectionMainFirstMount = (props) => {
  const classesSecionMain = stylesTemplate1SectionMain();
  return props.progressOfClient.length == 0 ? (
    <Box className={classesSecionMain.SectionMainFirstMount}>
      <Typography
        sx={{ fontSize: "25px", color: "#3498db", textAlign: "center" }}
      >
        Elija una clase en la sección izquierda.
      </Typography>
      <SubdirectoryArrowLeftIcon
        sx={{ fontSize: "25px", color: "#3498db", width: "3em", height: "3em" }}
      />
    </Box>
  ) : (
    <Box className={classesSecionMain.SectionMainFirstMount}>
      <Typography
        sx={{ fontSize: "25px", color: "#3498db", textAlign: "center" }}
      >
        Hola, ¿Quieres volver a la ultima sección en la que quedaste?, sino
        elije una clase.
      </Typography>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#3498db" }}
        onClick={() => props.handleBackToLastCLass()}
      >
        Si
      </Button>
    </Box>
  );
};

const ClientMenuSectionPresentationOfCourse = (props) => {
  const classesSecionMain = stylesTemplate1SectionMain();
  const classesBlue = stylesTemplate1ClientHome();
  return props.loading == true ? (
    <Loading />
  ) : (
    <Box className={classesSecionMain.SectionPresentationOfCourse}>
      <Box className={classesSecionMain.SectionPresentationOfCourseBox}>
        <Typography className={classesSecionMain.SectionPresentationText}>
          {props.title}
        </Typography>
        <Typography
          className={classesSecionMain.SectionPresentationTextDescription}
        >
          {props.description}
        </Typography>
        <Typography
          className={classesSecionMain.SectionPresentationTextDescription}
        >
          Aquí encontrarás algunos temas como:
        </Typography>
        <Typography
          className={classesSecionMain.SectionPresentationTextDescription}
        >
          {props.files.length < 3
            ? props.files.map((file) => {
                return `${file.name}, `;
              })
            : `${props.files[0].name}, ${props.files[1].name}, ${props.files[2].name},`}
        </Typography>
        <Button
          variant="contained"
          className={classesBlue.BlueColorFull}
          onClick={() => props.handleRegisterClientCourse()}
        >
          Iniciar Curso
        </Button>
      </Box>
    </Box>
  );
};

const ClientContentModul = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [content, setContent] = React.useState([]);

  const classes = StyleCircularProgressForModals();
  const classesClientContentModul = StyleForClientContentModul();
  const classesSecionMain = stylesTemplate1SectionMain();

  React.useEffect(() => {
    const getInitInformation = async () => {
      let data = [];
      data = await getContentOfModule(props.moduleId);
      setContent(data.contenido);
      setLoading(false);
    };
    getInitInformation();
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <AppBarModalTemplate1
        title={"Contenido del Modulo"}
        title_accion="Cerrar"
        accion={() => props.setOpenModal(false)}
      />
      <DialogContent className="scroll">
        {loading === true ? (
          <React.Fragment>
            <Box className={classes.progressLoading}>
              <CircularProgress />
              Cargando...
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box className={classesClientContentModul.sectionBox}>
              <Typography className={classesSecionMain.SectionPresentationText}>
                Descripción del Modulo:
              </Typography>
              <Typography
                className={classesSecionMain.SectionModulsTextDescription}
              >
                {props.moduleSelected.description}
              </Typography>
              <Typography className={classesSecionMain.SectionPresentationText}>
                Material:
              </Typography>
            </Box>
            {content.map((e) => {
              return (
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem>
                    <ListItemIcon>
                      <ContentCopyIcon />
                    </ListItemIcon>
                    <a
                      href={`${api}${e.files}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classesSecionMain.textDecoretionNone}
                    >
                      {e.name}
                    </a>
                  </ListItem>
                </List>
              );
            })}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const ModalScoreForClass = (props) => {
  const classes = StyleCircularProgressForModals();
  const classesModalScore = StylesForModalScore();
  const classesBlue = stylesTemplate1ClientHome();
  const [loading, setLoading] = React.useState(false);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <AppBarModalTemplate1
        title={"Califique la Clase"}
        title_accion="Cerrar"
        accion={() => props.setOpenModal(false)}
      />
      <DialogContent className="scroll">
        {loading === true ? (
          <React.Fragment>
            <Box className={classes.progressLoading}>
              <CircularProgress />
              Cargando...
            </Box>
          </React.Fragment>
        ) : (
          <Box className={classesModalScore.bodyBox}>
            <Rating
              name="simple-controlled"
              value={props.value}
              onChange={(event, newValue) => {
                props.setValue(newValue);
              }}
              size="large"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classesBlue.BlueColor}
          sx={{ marginTop: "50px" }}
          onClick={() => props.finishClass()}
        >
          Enviar y Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
});
