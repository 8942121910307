import React, { Component } from "react";
import { connect } from "react-redux";
import MenuTop from "../general/MenuTop";
import MenuBotton from "../general/MenuBotton";
import { CircularProgress, Fab, Grid } from "@material-ui/core";
import {
  showModalAddCategories,
  showModalEditCategories,
} from "../../redux/actions/actGenerics";
import {
  consulta,
  viewError,
  formulario,
  api,
  confirmAction,
  Toast
} from "../../global/js/funciones";
import AddIcon from "@material-ui/icons/Add";
import CreateCategories from "./CreateCategories";
import EditCategories from "./EditCategories";
import { CATEGORIES } from "../general/helperGeneric";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete, BtnEdit } from "../general/ButtonAccion";
import moment from "moment";


class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      valuesGenerics: [],
      process: "",
    };
  }

  async componentDidMount() {
    this.data();
  }
  async data() {
    this.setState({ charging: true });
    let valuesGenerics = await this.listValuesGenerics();
    this.setState({ valuesGenerics, charging: false });
  }

  async listValuesGenerics() {
    let f = CATEGORIES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  valuesGenericsChangeState = (state) => {
    this.setState({ valuesGenerics: state });
  };

  saveValueGeneric = async (data) => {
    let { showModalAddCategories } = this.props;
    formulario(`api/v1.0/values/crear`, data, "post", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalAddCategories(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
    });
  };
  editValueGeneric = async (data) => {
    let { showModalEditCategories } = this.props;
    formulario(`api/v1.0/values/editar`, data, "put", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          showModalEditCategories(false);
          this.data();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
    });
  };

  deleteValueGeneric= async (info) =>  {
    let f = info.id;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }


  
  view() {
    let { valuesGenerics } = this.state;
    let { showModalAddCategories, showModalEditCategories } = this.props;

    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteValueGeneric(data));
      };

      const onClickEdit = (process) => {
        this.setState({ process });
        showModalEditCategories(true);
      };

      let edit = (
        <BtnEdit
          color="#760068"
          callback={() => onClickEdit(rowData)}
          texto="Editar"
        />
      );
      let delet = (
        <BtnDelete
          color="#C62300"
          callback={() => onClickDelete(rowData)}
          texto="Eliminar"
        />
      );
      return (
        <div>
          {edit}
          {delet}
        </div>
      );
    };

    return (
      <>
        <Grid style={{ paddingTop: 65, marginBottom: 80 }}>
          <ListarDatos
            id="tbl_list_generic_categories"
            datos={valuesGenerics}
            avatarImg={true}
            avatar={({ files }) => `${api}${files}`}
            acciones={(row) => actions(row)}
            fila_principal={({ name }) => name.toUpperCase()}
            filas={[
              {
                id: "date_add",
                enLista: true,
                show: ({ date_add }) =>
                  moment(date_add).format("YYYY-MM-DD, h:mm a"),
              },
              {
                id: "description",
                enLista: true,
                show: ({ description }) => description,
              },
            ]}
          />
        </Grid>
        <Fab
          style={{ zIndex: 2, position: "fixed", bottom: 50, right: 20 }}
          color="primary"
          aria-label="add"
          onClick={() => showModalAddCategories(true)}
        >
          <AddIcon />
        </Fab>
      </>
    );
  }

  render() {
    let { charging, process, valuesGenerics } = this.state;
    let {
      showModalAddCategories,
      modalAddCategories,
      showModalEditCategories,
      modalEditCategories,
      updateMessage,
    } = this.props;
    return (
      <>
        <MenuTop
          label="categorías"
          valuesGenericsChangeState={this.valuesGenericsChangeState}
          valuesGenerics={valuesGenerics}
          data={CATEGORIES}
        />
        <MenuBotton value={3} />
        {!charging ? (
          this.view()
        ) : (
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 300, maxWidth: "100%" }}
          >
            <CircularProgress size={50} />
          </Grid>
        )}
        <CreateCategories
          updateMessage={updateMessage}
          showModalAddCategories={showModalAddCategories}
          modalAddCategories={modalAddCategories}
          saveValueGeneric={this.saveValueGeneric}
        />
        <EditCategories
          process={process}
          updateMessage={updateMessage}
          showModalEditCategories={showModalEditCategories}
          modalEditCategories={modalEditCategories}
          editValueGeneric={this.editValueGeneric}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { modalAddCategories, modalEditCategories } = state.redGenerics;
  return {
    modalAddCategories,
    modalEditCategories,
  };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  showModalAddCategories,
  showModalEditCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
