import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Avatar,
  List,
  AppBar,
  Toolbar,
  InputBase,
  ListSubheader,
  Hidden,
} from "@material-ui/core";
import user from "../../global/images/user_list.png";
import { BtnAccion } from "./ButtonAccion";
import SearchIcon from "@material-ui/icons/Search";
import Popover from "@material-ui/core/Popover";
import Finish from "./Finish";
import sin_informacion from "../../global/images/sin_informacion.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    backgroundColor: "white",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "fixed",
    // bottom: "70px",
    left: "45%",
    zIndex: 1000,
    top: "83%",
    [theme.breakpoints.up("sm")]: {
      top: "89%",
      left: "50%",
    },
    // margin: '0 auto',
  },
  title: {
    margin: "0 auto",
    color: "#757575",
    display: "none",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    height: 40,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff9c4",
    "&:hover": {
      backgroundColor: "#fff59d",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: 40,
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#c79e32",
  },
  inputRoot: {
    // color: '#9e9e9e',
    // fontWeight: 'none',
    height: 30,
  },
  inputInput: {
    height: 40,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  IconButton: {
    marginLeft: 5,
  },
}));

function Opciones({ title, dato_buscar, setBuscar, buscar, accionesList }) {
  const classes = useStyles();
  let isFunction = (functionToCheck) =>
    functionToCheck &&
    {}.toString.call(functionToCheck) === "[object Function]";
  return (
    <AppBar
      position="static"
      className={classes.appBar}
      elevation={0}
      id="appBar_listar_datos"
    >
      <Toolbar>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.grow} />
        {buscar && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              name="dato_buscar"
              value={dato_buscar}
              onChange={({ target }) => setBuscar(target.value)}
              placeholder="Buscar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        )}
        {isFunction(accionesList) && accionesList()}
      </Toolbar>
    </AppBar>
  );
}

function AccionesApp({ acciones }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <BtnAccion callback={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>{acciones()}</div>
      </Popover>
    </div>
  );
}

class ListarDatos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dato_buscar: "",
      registros: [],
      seleccionado: -1,
    };
  }

  componentDidMount() {
    let { datos } = this.props;
    this.setState({ registros: datos });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  isFunction = (functionToCheck) =>
    functionToCheck &&
    {}.toString.call(functionToCheck) === "[object Function]";

  ClickRow = (row, i) => {
    let { accion_general } = this.props;
    this.setState({ seleccionado: i });
    accion_general(row, i);
  };

  render() {
    let {
      accionApp,
      cargando,
      avatarImg,
      opciones,
      datos,
      fila_principal,
      filas,
      avatar,
      color_avatar,
      acciones,
      css,
      accionesList,
    } = this.props;
    let { seleccionado } = this.state;

    return (
      <div id="contenido_listar_datos">
        {
          <>
            <List
              style={css.list && css.list}
              className="scroll"
              subheader={
                <ListSubheader
                  component="div"
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #eeeeee",
                  }}
                >
                  <div>
                    {opciones && <Opciones accionesList={accionesList} />}
                  </div>
                </ListSubheader>
              }
            >
              {datos.length === 0 || cargando ? (
                <Finish
                  image={sin_informacion}
                  message="No hay Información."
                  widthImg="15%"
                  marginTop="7%"
                />
              ) : (
                <div>
                  {" "}
                  {datos.map((row, i) => {
                    return (
                      <div key={i}>
                        <ListItem
                          onClick={() => this.ClickRow(row, i)}
                          style={
                            seleccionado === i
                              ? {
                                  backgroundColor: "#F2FAF3",
                                  borderBottom: "1px solid #eeeeee",
                                }
                              : {
                                  backgroundColor: "white",
                                  borderBottom: "1px solid #eeeeee",
                                }
                          }
                        >
                          {avatar && (
                            <ListItemAvatar>
                              {avatarImg ? (
                                <Avatar
                                  src={avatar(row, i)}
                                  onError={(e) => {
                                    e.target.src = user;
                                  }}
                                />
                              ) : (
                                <Avatar
                                  style={
                                    this.isFunction(color_avatar)
                                      ? color_avatar(row, i)
                                      : color_avatar
                                  }
                                >
                                  {this.isFunction(avatar)
                                    ? avatar(row, i)
                                    : row[avatar]}
                                </Avatar>
                              )}
                            </ListItemAvatar>
                          )}
                          <ListItemText
                            primary={""}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  key={-1}
                                  style={{ color: "black" }}
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {this.isFunction(fila_principal)
                                    ? fila_principal(row)
                                    : row[fila_principal]}
                                  <br />
                                </Typography>
                                {filas.map(
                                  (
                                    {
                                      show = "",
                                      nombre = "",
                                      id,
                                      enLista = true,
                                    },
                                    j
                                  ) =>
                                    enLista &&
                                    (show ? show(row, i) : row[id]) && (
                                      <Typography
                                        key={j}
                                        component="span"
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {nombre && `${nombre}`}
                                        {show ? show(row, i) : row[id]}
                                        <br />
                                      </Typography>
                                    )
                                )}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction
                            onClick={() => this.setState({ seleccionado: i })}
                          >
                            {accionApp ? (
                              <>
                                {this.isFunction(acciones) && (
                                  <Hidden xsDown>{acciones(row, i)}</Hidden>
                                )}
                                {this.isFunction(acciones) && (
                                  <Hidden smUp>
                                    <AccionesApp
                                      acciones={() => acciones(row, i)}
                                    />
                                  </Hidden>
                                )}
                              </>
                            ) : (
                              this.isFunction(acciones) && acciones(row, i)
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    );
                  })}
                </div>
              )}
            </List>
          </>
        }
      </div>
    );
  }
}

ListarDatos.propTypes = {
  //variables
  datos: PropTypes.array.isRequired,
  title: PropTypes.string,
  sub_title: PropTypes.string,
  buscar: PropTypes.bool,
  filas: PropTypes.array.isRequired,
  color_avatar: PropTypes.func,
  css: PropTypes.object,
  accion_general: PropTypes.func,
};

ListarDatos.defaultProps = {
  title: "",
  sub_title: "",
  avatar: "",
  cargando: false,
  accionApp: true,
  opciones: false,
  avatarImg: false,
  datos: [],
  buscar: true,
  color_avatar: () => ({ backgroundColor: "#f5f5f5", color: "#757575" }),
  css: {
    list: { padding: "0px 0px 0px 0px" },
    appBar: { padding: "0px 0px 0px 0px" },
  },
  acciones: "",
  accionesList: "",
  accion_general: () => "",
};

export default ListarDatos;
