
const ACT_message = 'ACT_message';
const MODAL_DELETE = 'MODAL_DELETE';




const updateMessage = message => {
  return {
    type: ACT_message,
    message,
  }
};
const showModalDelete = show => {
  return {
    type: MODAL_DELETE,
    show,
  }
};


module.exports = {
 
  ACT_message,
  updateMessage,
  MODAL_DELETE,
  showModalDelete
};
