import React from "react";
import { consulta } from "./funciones";
import {
  COURSES,
  MODULES,
  QUESTION,
} from "../../components/general/helperGeneric";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
export const getOneValueOfValuesGenerics = (data) => {
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/values/${data}/listSimple`,
      null,
      null,
      (error, estado, resp) => {
        resolve(
          estado === 200 && !error
            ? { resp, status: estado }
            : { resp: null, status: estado }
        );
      },
      false
    );
  });
};

export const getCoursesOfSub = async (data, filters = []) => {
  filters = await changesFilter(filters);
  let f = COURSES; //Aqui debe ir el helper
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${f}/list/${data}/permission?${filters}`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getModulsOfSubs = (data) => {
  let f = MODULES; //Aqui debe ir el helper
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/values/${f}/list/${data}/modulesFiles`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getQuestionsAndAnswers = (modul) => {
  let f = QUESTION;
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/exam/${modul}/questions/${f}`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const PreviewGetModulsOfSubs = (data) => {
  let f = MODULES; //Aqui debe ir el helper
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${f}/list/${data}/previewOfContent`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const SearchCourse = (idSub, data) => {
  let f = COURSES; //Aqui debe ir el helper
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${f}/list/${idSub}/searchCourse/${data}`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getClientCourse = (data) => {
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${localStorage.getItem("idOfClient")}/list/${data}`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getClientCourseProgress = (data) => {
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/listProcess/${data}`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getContentOfModule = (data) => {
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${data}/listContent`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getUserByNumber = (data) => {
  return new Promise((resolve) => {
    consulta(
      `api/v1.0/process/${data}/validateUser`,
      null,
      null,
      (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      },
      false
    );
  });
};

export const getPermissions = async () => {
  return new Promise((resolve) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let uId = urlParams.get("uId");
    let contentPk = "";
    if (uId) {
      let uri = uId.split("-");
      uId = uri[0];
      contentPk = uri[1];
    }
    resolve({ uId, contentPk });
  });
};

export const changesFilter = (filtros = []) => {
  return new Promise((resolve) => {
    let f = "";
    filtros.map(({ key, value }) => (f = `${f}&${key}=${value}`));
    resolve(f);
  });
};

export const setDataClient = ({ phone_number, email, full_name, id }) => {
  localStorage.setItem("numberOfClient", phone_number);
  localStorage.setItem("idOfClient", id);
  localStorage.setItem("emailOfClient", !!email ? email : "N/A");
  localStorage.setItem("nameOfClient", !!full_name ? full_name : "N/A");
};

export function TransformText({ text }) {
  let list = text.search("!");
  let listF = text.search("¡");
  if (list != -1 && listF != -1) {
    let resp = text.split("¡");
    return resp.map((e, i) => {
      let p = e.split("!");
      if (p.length == 1) return "";
      return (
        <List key={i}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={``}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {`${p[0]}`}
                  </Typography>
                  <p>{p[1]}</p>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      );
    });
  } else return text;
}
