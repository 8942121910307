import {
    MODAL_ADD_CAT,
    MODAL_ADD_MOD,
    MODAL_EDIT_CAT,
    MODAL_EDIT_MOD,
    MODAL_ADD_SUB,
    MODAL_EDIT_SUB,
    MODAL_ADD_COURSES,
    MODAL_EDIT_COURSES,
    MODAL_LIST_COURSES_CAT,
    MODAL_ADD_COURSES_CAT,
    MODAL_LIST_SUSCRIPTION_COU,
    MODAL_ADD_SUSCRIPTION_COR,
    MODAL_LIST_COURSES_MOD,
    MODAL_ADD_COURSES_MOD,
    MODAL_ADD_COURSES_MOD_WEIGHT,
    MODAL_LIST_MODULES_MATERIAL,
    MODAL_ADD_MODULES_MATERIAL,
    MODAL_LIST_MODULES_CONTENTS,
    MODAL_ADD_MODULES_CONTENTS,
    MODAL_ADD_EXAM,
    MODAL_EDIT_EXAM,
    MODAL_LIST_EXAM_QUESTION,
    MODAL_ADD_EXAM_QUESTION,
    MODAL_LIST_EXAM_ANSWER,
    MODAL_ADD_EXAM_ANSWER,
    MODAL_LIST_MODULES_EXAM,
    MODAL_ADD_MODULES_EXAM,
    MODAL_LIST_SUSCRIPTION_DETAIL,

  } from "../actions/actGenerics";
  
  const initialState = {
    modalAddCategories: false,
    modalAddModules: false,
    modalEditModules: false,
    modalEditCategories: false,
    modalAddSubscriptions: false,
    modalEditSubscriptions: false,
    modalAddCourses: false,
    modalEditCourses: false,
    modalListCoursesCategories: false,
    modalAddCoursesCategories: false,
    modalListCoursesModules: false,
    modalAddCoursesModules: false,
    modalAddCoursesModulesWeight: false,
    modalAddModulesMaterial: false,
    modalListModulesMaterial: false,
    modalAddModulesContents: false,
    modalListModulesContents: false,
    modalAddCoursesSuscription: false,
    modalListCoursesSuscription: false,
    modalAddExam: false,
    modalEditExam: false,
    modalAddExamQuestion: false,
    modalListExamQuestion: false,
    modalAddExamAnswer: false,
    modalListExamAnswer: false,
    modalAddModulesExam: false,
    modalListModulesExam: false,
    modalListSuscriptionDetail: false,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case MODAL_ADD_CAT:
        return Object.assign({}, state, {
          modalAddCategories: action.show
        });
        case MODAL_ADD_MOD:
        return Object.assign({}, state, {
          modalAddModules: action.show
        });
        case MODAL_EDIT_MOD:
        return Object.assign({}, state, {
          modalEditModules: action.show
        });
        case MODAL_EDIT_CAT:
        return Object.assign({}, state, {
          modalEditCategories: action.show
        });
        case MODAL_ADD_SUB:
        return Object.assign({}, state, {
          modalAddSubscriptions: action.show
        });
        case MODAL_EDIT_SUB:
        return Object.assign({}, state, {
          modalEditSubscriptions: action.show
        });
        case MODAL_ADD_COURSES:
        return Object.assign({}, state, {
          modalAddCourses: action.show
        });
        case MODAL_EDIT_COURSES:
        return Object.assign({}, state, {
          modalEditCourses: action.show
        });
        case MODAL_LIST_COURSES_CAT:
          return Object.assign({}, state, {
            modalListCoursesCategories: action.show
          });
        case MODAL_ADD_COURSES_CAT:
          return Object.assign({}, state, {
            modalAddCoursesCategories: action.show
          });
        case MODAL_LIST_COURSES_MOD:
          return Object.assign({}, state, {
            modalListCoursesModules: action.show
          });
        case MODAL_ADD_COURSES_MOD:
          return Object.assign({}, state, {
            modalAddCoursesModules: action.show
          });
        case MODAL_ADD_COURSES_MOD_WEIGHT:
          return Object.assign({}, state, {
            modalAddCoursesModulesWeight: action.show
          });
        case MODAL_LIST_MODULES_MATERIAL:
          return Object.assign({}, state, {
            modalListModulesMaterial: action.show
          });
        case MODAL_ADD_MODULES_MATERIAL:
          return Object.assign({}, state, {
            modalAddModulesMaterial: action.show
          });
        case MODAL_LIST_MODULES_CONTENTS:
          return Object.assign({}, state, {
            modalListModulesContents: action.show
          });
        case MODAL_ADD_MODULES_CONTENTS:
          return Object.assign({}, state, {
            modalAddModulesContents: action.show
          });
        case MODAL_LIST_SUSCRIPTION_COU:
          return Object.assign({}, state, {
            modalListCoursesSuscription: action.show
          });
     case MODAL_LIST_SUSCRIPTION_DETAIL:
          return Object.assign({}, state, {
            modalListSuscriptionDetail: action.show
          });
        case MODAL_ADD_SUSCRIPTION_COR:
          return Object.assign({}, state, {
            modalAddCoursesSuscription: action.show
          });
        case MODAL_ADD_EXAM:
          return Object.assign({}, state, {
            modalAddExam: action.show
          });
        case MODAL_EDIT_EXAM:
        return Object.assign({}, state, {
          modalEditExam: action.show
        });
        case MODAL_LIST_EXAM_QUESTION:
          return Object.assign({}, state, {
            modalListExamQuestion: action.show
          });
        case MODAL_ADD_EXAM_QUESTION:
          return Object.assign({}, state, {
            modalAddExamQuestion: action.show
          });
      case MODAL_LIST_EXAM_ANSWER:
          return Object.assign({}, state, {
            modalListExamAnswer: action.show
          });
      case MODAL_ADD_EXAM_ANSWER:
          return Object.assign({}, state, {
            modalAddExamAnswer: action.show
          });
      case MODAL_LIST_MODULES_EXAM:
          return Object.assign({}, state, {
            modalListModulesExam: action.show
          });
      case MODAL_ADD_MODULES_EXAM:
          return Object.assign({}, state, {
            modalAddModulesExam: action.show
          });
      default:
        return state;
    }
  };
  
  export default reducer;
  