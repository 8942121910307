import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { consulta, viewError, Toast, confirmAction } from "../../global/js/funciones";
import CourseCategoriesAdd from "./CourseCategoriesAdd";
import moment from "moment";
import { CATEGORIES } from "../general/helperGeneric";


class CourseCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      categories: [],
    };
  }

  async componentDidUpdate({
    modalListCoursesCategories,
    modalAddCoursesCategories,
  }) {
    let { modalListCoursesCategories: modalListCoursesCategoriesNew } =
      this.props;
    if (modalListCoursesCategoriesNew !== modalListCoursesCategories) {
      this.data();
    }
    let { modalAddCoursesCategories: modalAddCoursesCategoriesNew } =
      this.props;
    if (modalAddCoursesCategoriesNew !== modalAddCoursesCategories) {
      this.data();
    }
  }

  async data() {
    this.setState({ charging: true });
    let categories = await this.listCategories();
    this.setState({ categories, charging: false });
  }

  async listCategories() {
    let { process } = this.props;
    let data = process.id;
    let f = CATEGORIES; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${data}/permission`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  deleteCategorie(info) {
    let f = info.principal.id;
    let data = info.secondary;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/${data}/permission`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          Toast.fire({ icon: types, title });
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  addPermissionGeneric = async (data) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/permission/add`,
        data,
        "post",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  };


  view() {
    let { charging, categories } = this.state;
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteCategorie(data));
      };

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;
      return <div>{delet}</div>;
    };

    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_categories"
          datos={categories}
          acciones={(row) => actions(row)}
          fila_principal={({ principal: { name } }) => name.toUpperCase()}
          filas={[
            {
              show: ({ principal: { date_add } }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
              id: "date_add",
              enLista: true,
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListCoursesCategories,
      modalListCoursesCategories,
      showModalAddCoursesCategories,
      modalAddCoursesCategories,
      process,
    } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListCoursesCategories}
          onClose={() => showModalListCoursesCategories(false)}
        >
          <AppBarModal
            title={" ¡ Categorías Curso ! "}
            showMessageModal={() => showModalListCoursesCategories(false)}
            title_accion="Cerrar"
            accion={() => showModalListCoursesCategories(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              <Fab
                style={{ zIndex: 1, position: "relative" }}
                color="primary"
                aria-label="add"
                onClick={() => showModalAddCoursesCategories(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
        <CourseCategoriesAdd
          process={process}
          showModalAddCoursesCategories={showModalAddCoursesCategories}
          modalAddCoursesCategories={modalAddCoursesCategories}
          addPermissionGeneric={this.addPermissionGeneric}
        />
      </>
    );
  }
}
CourseCategories.propTypes = {
  modalListCoursesCategories: PropTypes.bool.isRequired,
  modalAddCoursesCategories: PropTypes.bool.isRequired,
};

export default CourseCategories;
