import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { stylesTemplate1Loading } from "../../global/css/components/StylesTemplate1";

export function Loading() {
  const classes = stylesTemplate1Loading();
  return (
    <React.Fragment>
      <Box className={classes.boxLoading}>
        <CircularProgress />
        Cargando...
      </Box>
    </React.Fragment>
  );
}

export function LoadingClientMenu() {
  const classes = stylesTemplate1Loading();
  return (
    <React.Fragment>
      <Box className={classes.boxLoadingMenu}>
        <CircularProgress />
        Cargando...
      </Box>
    </React.Fragment>
  );
}
