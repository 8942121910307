import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

export default function Information({ title, description, type }) {
  return (
    <Stack sx={{ width: "100%" }}>
      <Alert severity={type}>
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    </Stack>
  );
}
