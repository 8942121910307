import React, { useEffect } from "react";
import ClientMenu from "./Template1PlayerMenu";
import { Header } from "./Template1Headers";
import {
  StyleCircularProgressForModals,
  stylesTemplate1ClientHome,
  stylesTemplate1SectionMain,
} from "../../global/css/components/StylesTemplate1";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  getClientCourse,
  getCoursesOfSub,
  getOneValueOfValuesGenerics,
  PreviewGetModulsOfSubs,
  getPermissions,
  setDataClient,
} from "../../global/js/Requests";
import { useParams } from "react-router-dom";
import { Loading } from "./Template1Loadings";
import { consulta, api } from "../../global/js/funciones";
import { AppBarModalTemplate1 } from "./Template1AppBarsModal";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Suscribe,
  EmptyData,
  EmptySuscription,
  EmptyDataUser,
} from "./Messages";
import sin_informacion from "../../global/images/sin_informacion.png";
import Template1Modal from "./Template1Modal";

const statesOfCourses = [
  { label: "En Curso" },
  { label: "Aprobado" },
  { label: "No aprobado" },
];

export function Template1() {
  const [view, setView] = React.useState("Client");
  const [courseSelected, setCourseSelected] = React.useState({});
  const [courses, setCourses] = React.useState([]);
  const [subInformation, setSubInformation] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loadingCourses, setLoadingCourses] = React.useState(false);
  const [premium, setPremium] = React.useState(false);
  const [errorSuscription, setErrorSuscription] = React.useState(false);
  const [isDataUser, setIsDataUser] = React.useState(false);
  const [openModalFirstRequest, setOpenModalFirstRequest] =
    React.useState(false);
  const { id } = useParams();

  const changeCourseSelected = async (course) => {
    setLoading(true);

    let dataCoursesClient = await getClientCourse(course.principal.id);
    console.log(dataCoursesClient);
    if (dataCoursesClient == null) {
      setCourseSelected(course);
      setOpenModalFirstRequest(true);
      setLoading(false);
    } else {
      setCourseSelected(course);
      setView("Menu");
      setLoading(false);
    }
  };

  const handleRegisterClientCourse = async () => {
    setOpenModalFirstRequest(false);
    setLoading(true);
    let url = `api/v1.0/process/crear`;
    let data = {
      id_user: localStorage.getItem("idOfClient"),
      id_course: courseSelected.principal.id,
    };

    const callback = (error, estado, resp) => {
      if (!error) {
        if (estado === 200) {
          setView("Menu");
        }
      }

      setLoading(false);
    };

    await consulta(url, data, "POST", callback, false);
  };

  React.useEffect(() => {
    const getInitInformation = async () => {
      let { uId } = await getPermissions();
      let { data, premium, isDataUser, dataUser, errorSuscription } =
        await getCoursesOfSub(id, [{ key: "uId", value: uId ? uId : "" }]);
      if (isDataUser) setDataClient(dataUser);
      setPremium(premium);
      setCourses(data);
      console.log(data);
      setIsDataUser(isDataUser);
      setErrorSuscription(errorSuscription);
      if (premium && isDataUser && !errorSuscription) {
        let { resp } = await getOneValueOfValuesGenerics(id);
        setSubInformation(resp);
      }
      setLoading(false);
    };

    getInitInformation();
  }, []);

  const viewToRender = () => {
    if (loading) return <Loading />;
    else if (errorSuscription) return <EmptySuscription />;
    else if (!isDataUser) return <EmptyDataUser />;
    else if (!premium) return <Suscribe />;
    else return showContent();
  };

  const showContent = () => {
    switch (view) {
      case "Client":
        return (
          <React.Fragment>
            <Header
              subInformation={subInformation}
              courses={courses}
              setCourses={setCourses}
              setLoadingCourses={setLoadingCourses}
            />
            {loadingCourses ? (
              <Loading />
            ) : (
              <ClientHome
                changeCourseSelected={changeCourseSelected}
                courses={courses}
                subInformation={subInformation}
                loading={loading}
                premium={premium}
                courseSelected={courseSelected}
                openModalFirstRequest={openModalFirstRequest}
                setOpenModalFirstRequest={setOpenModalFirstRequest}
                handleRegisterClientCourse={handleRegisterClientCourse}
              />
            )}
          </React.Fragment>
        );
      case "Menu":
        return (
          <ClientMenu
            Course={courseSelected}
            setView={setView}
            setViewTemplate1={setView}
          />
        );
      default:
        return <div></div>;
    }
  };

  return <div>{viewToRender()}</div>;
}

const ClientHome = (props) => {
  const classes = stylesTemplate1ClientHome();
  const [isOpenDescription, setOpenDescription] = React.useState(false);
  const [description, setDescription] = React.useState("");

  return (
    <>
      <Template1Modal
        open={isOpenDescription}
        setOpen={setOpenDescription}
        content={description}
        title="Detalle del curso"
      />
      <Box className={classes.SectionMain}>
        <Box className={classes.SectionMainMiddleBox}>
          <Box className={classes.SectionMainBoxCourses}>
            <Box className={classes.SectionMainBoxCoursesCards}>
              {props.courses.length == 0 && <EmptyData />}
              {props.courses.map((e) => {
                const description = e.principal.description;
                return (
                  <Card className={classes.SectionMainBoxCoursesCardsBox}>
                    <CardActionArea>
                      <img
                        className={classes.SectionMainBoxCoursesCardsBoxImage}
                        onClick={() => props.changeCourseSelected(e)}
                        src={
                          e.principal.files
                            ? `${api}${e.principal.files}`
                            : sin_informacion
                        }
                        alt="Imagen Contentido"
                      />

                      <CardContent className={classes.CardsPadding}>
                        <Grid container>
                          <Grid
                            xs={12}
                            onClick={() => props.changeCourseSelected(e)}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{ color: "#fff" }}
                              className={
                                classes.SectionMainBoxCoursesCardsBoxTittle
                              }
                            >
                              {e.principal.name}
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            <Typography
                              className={
                                classes.SectionMainBoxCoursesCardsBoxDescription
                              }
                              variant="body2"
                              color="text.secondary"
                            >
                              {description.length > 100 ? (
                                `${description.slice(0, 100)}...`
                              ) : (
                                <span
                                  onClick={() => props.changeCourseSelected(e)}
                                >
                                  {" "}
                                  description
                                </span>
                              )}
                              {description.length > 100 && (
                                <span
                                  className={classes.showDescriptionCourse}
                                  onClick={() => {
                                    setDescription(description);
                                    setOpenDescription(true);
                                  }}
                                >
                                  ver mas
                                </span>
                              )}
                            </Typography>
                            <Typography
                              className={
                                classes.SectionMainBoxCoursesCardsBoxDescriptionApp
                              }
                              variant="body2"
                              color="text.secondary"
                            >
                              {description.length > 50 ? (
                                `${description.slice(0, 50)}...`
                              ) : (
                                <span
                                  onClick={() => props.changeCourseSelected(e)}
                                >
                                  {" "}
                                  description
                                </span>
                              )}

                              {description.length > 50 && (
                                <span
                                  className={classes.showDescriptionCourse}
                                  onClick={() => {
                                    setDescription(description);
                                    setOpenDescription(true);
                                  }}
                                >
                                  ver mas
                                </span>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent
                        className={classes.CardsPadding}
                        onClick={() => props.changeCourseSelected(e)}
                      >
                        <Grid container>
                          <Grid xs={12}>
                            <Typography
                              className={
                                classes.SectionMainBoxCoursesCardsBoxState
                              }
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Estado: ${
                                e.sw !== null
                                  ? statesOfCourses[parseInt(e.sw) - 1].label
                                  : "Sin iniciar"
                              }`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      {props.openModalFirstRequest === true ? (
        <ModalFirstClass
          handleRegisterClientCourse={props.handleRegisterClientCourse}
          course={props.courseSelected}
          openModal={props.openModalFirstRequest}
          setOpenModal={props.setOpenModalFirstRequest}
        />
      ) : (
        ""
      )}
    </>
  );
};

const ModalFirstClass = (props) => {
  const classesSecionMain = stylesTemplate1SectionMain();
  const classes = StyleCircularProgressForModals();
  const classesBlue = stylesTemplate1ClientHome();
  const [loading, setLoading] = React.useState(true);
  const [content, setContent] = React.useState([]);
  React.useEffect(() => {
    const getInitInformation = async () => {
      let data = [];
      data = await PreviewGetModulsOfSubs(parseInt(props.course.principal.id));
      setContent(data);
      setLoading(false);
    };
    getInitInformation();
  }, []);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
    >
      <AppBarModalTemplate1
        title={props.course.principal.name}
        title_accion="Cerrar"
        accion={() => props.setOpenModal(false)}
      />
      <DialogContent className="scroll">
        {loading === true ? (
          <React.Fragment>
            <Box className={classes.progressLoading}>
              <CircularProgress />
              Cargando...
            </Box>
          </React.Fragment>
        ) : (
          <Box className={classesSecionMain.SectionPresentationOfCourseBox}>
            <Typography className={classesSecionMain.SectionPresentationText}>
              Descripción del Curso:
            </Typography>
            <Typography sx={{ textAlign: "justify" }}>
              {props.course.principal.description}
            </Typography>
            <Typography className={classesSecionMain.SectionPresentationText}>
              Algunos modulos que hacen parte de este curso:
            </Typography>
            <Typography>
              <List dense={true}>
                {content.length < 3 ? (
                  content.map((e) => {
                    return (
                      <ListItem>
                        <ListItemIcon>
                          <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText primary={e.principal.name} />
                      </ListItem>
                    );
                  })
                ) : (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        <ContentCopyIcon />
                      </ListItemIcon>
                      <ListItemText primary={content[0].principal.name} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ContentCopyIcon />
                      </ListItemIcon>
                      <ListItemText primary={content[1].principal.name} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ContentCopyIcon />
                      </ListItemIcon>
                      <ListItemText primary={content[2].principal.name} />
                    </ListItem>
                  </>
                )}
              </List>
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classesBlue.BlueColor}
          onClick={() => props.handleRegisterClientCourse()}
        >
          Iniciar Curso
        </Button>
      </DialogActions>
    </Dialog>
  );
};
