import { createTheme } from "@material-ui/core";

export const styleApp = createTheme({
  palette: {
    primary: {
      main: "#10895D",
    },
    secondary: {
      main: "#3498db",
    },
  },
});
