import React, { useState, useEffect } from "react";
import MenuTop from "../general/MenuTop";
import MenuBotton from "../general/MenuBotton";
import { CircularProgress, Fab, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete, BtnEdit } from "../general/ButtonAccion";
import moment from "moment";
import {
  confirmAction,
  consulta,
  Toast,
  viewError,
} from "../../global/js/funciones";

export default function User() {
  const [charging, setCharging] = useState(false);
  const [modalAddUser, setModalAddUser] = useState(false);
  const [modalUpdateUser, setModalUpdateUser] = useState(false);
  const [user, setUser] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setCharging(true);
    consulta(`api/v1.0/users`, null, null, (error, estado, resp) => {
      if (estado === 200) setData(resp);
      else setData([]);
      setCharging(false);
    });
  };

  const addUser = async (data, callback) => {
    callback(true);
    consulta(`api/v1.0/users/add`, data, "post", (error, estado, resp) => {
      let title = "Ha ocurrido un error, contacte con el administrador.",
        types = "error";
      if (!error) {
        if (estado === 200) {
          types = "success";
          title = resp.title;
          setModalAddUser(false);
          getData();
        } else {
          types = "info";
          title = resp.title ? resp.title : viewError(resp);
        }
      }
      Toast.fire({ icon: types, title });
      callback(false);
    });
  };
  const updateUser = async (data, callback) => {
    callback(true);
    consulta(
      `api/v1.0/users/${user.id}/update`,
      data,
      "put",
      (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.",
          types = "error";
        if (!error) {
          if (estado === 200) {
            types = "success";
            title = resp.title;
            setModalUpdateUser(false);
            getData();
          } else {
            types = "info";
            title = resp.title ? resp.title : viewError(resp);
          }
        }
        Toast.fire({ icon: types, title });
        callback(false);
      }
    );
  };
  const deleteUser = async () => {
    setCharging(true);
    consulta(
      `api/v1.0/users/${user.id}/delete`,
      data,
      "put",
      (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.",
          types = "error";
        if (!error) {
          if (estado === 200) {
            types = "success";
            title = resp.title;
            getData();
          } else {
            types = "info";
            title = resp.title ? resp.title : viewError(resp);
          }
        }
        Toast.fire({ icon: types, title });
        setCharging(false);
      }
    );
  };

  const valuesGenericsChangeState = (state) => {
    setData(state)
  };


  const render = () => {
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        setUser(data);
        confirmAction(deleteUser);
      };

      const onClickEdit = (data) => {
        setUser(data);
        setModalUpdateUser(true);
      };

      let edit = (
        <BtnEdit
          color="#760068"
          callback={() => onClickEdit(rowData)}
          texto="Editar"
        />
      );
      let delet = (
        <BtnDelete
          color="#C62300"
          callback={() => onClickDelete(rowData)}
          texto="Eliminar"
        />
      );
      return (
        <div>
          {edit}
          {delet}
        </div>
      );
    };

    return (
      <>
        <Grid style={{ paddingTop: 65, marginBottom: 80 }}>
          <ListarDatos
            id="tbl_list_users"
            datos={data}
            acciones={(row) => actions(row)}
            fila_principal={({ email }) => email.toUpperCase()}
            filas={[
              {
                id: "date_add",
                enLista: true,
                show: ({ date_add }) =>
                  moment(date_add).format("YYYY-MM-DD, h:mm a"),
              },
            ]}
          />
        </Grid>
        <Fab
          style={{ zIndex: 2, position: "fixed", bottom: 50, right: 20 }}
          color="primary"
          aria-label="add"
          onClick={() => setModalAddUser(true)}
        >
          <AddIcon />
        </Fab>
      </>
    );
  };

  return (
    <>
      <MenuTop
        label="Usuarios"
        valuesGenericsChangeState={valuesGenericsChangeState}
        valuesGenerics={data}
        data={""}
      />
      <MenuBotton value={5} />
      {!charging ? (
        render()
      ) : (
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          style={{ paddingTop: 300, maxWidth: "100%" }}
        >
          <CircularProgress size={50} />
        </Grid>
      )}
      <CreateUser
        modalAddUser={modalAddUser}
        showModalAddUser={setModalAddUser}
        callback={addUser}
      />
      {modalUpdateUser && (
        <UpdateUser
          modalAddUser={modalUpdateUser}
          showModalAddUser={setModalUpdateUser}
          callback={updateUser}
          emailDefault={!!user ? user.email : ""}
        />
      )}
    </>
  );
}
