import {
  Avatar,
  Box,
  Button,
  DialogActions,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  Grid,
  alpha,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  InputBase,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import {
  stylesTemplate1,
  stylesHeader,
  stylesTemplate1ClientHome,
} from "../../global/css/components/StylesTemplate1";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { AppBarModalTemplate1 } from "./Template1AppBarsModal";
import SearchIcon from "@material-ui/icons/Search";
import { SearchCourse, TransformText } from "../../global/js/Requests";
import { useParams } from "react-router-dom";
import notificaciones from "../../global/images/notificaciones.svg";
import profile from "../../global/images/Profile.svg";
import { consulta, Toast } from "../../global/js/funciones";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "30%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 5),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalConditions, setOpenModalContions] = React.useState(false);

  const [tittleConditions, setTittleConditions] = React.useState("");
  const [descriptionConditions, setDescriptionConditions] = React.useState("");

  const [openModalNotifications, setOpenModalNotifications] = React.useState();

  const [openModalAccount, setOpenModalAccount] = React.useState(false);

  const classes = stylesTemplate1();
  const { id } = useParams();
  const tool = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotifications = () => {
    setOpenModalNotifications(true);
  };

  const handleOpenModalConditions = (data) => {
    if (data === "terms") {
      setTittleConditions("Terminos y Condiciones");
      setDescriptionConditions(props.subInformation.valuea);
    } else if (data === "politics") {
      setTittleConditions("Politicas");
      setDescriptionConditions(props.subInformation.valueb);
    }

    setOpenModalContions(true);
  };

  const handleKeyPress = async (event) => {
    if (event.keyCode === 13) {
      props.setLoadingCourses(true);
      let inputValue = document.getElementById("search").value;
      let valuesGenerics = await SearchCourse(
        parseInt(id),
        inputValue ? inputValue : "all"
      );
      props.setCourses(valuesGenerics);
      props.setLoadingCourses(false);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar
          sx={{ justifyContent: "space-between" }}
          className={classes.ToolBarStyle}
        >
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <AutoStoriesIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Cursos
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onKeyDown={handleKeyPress}
              id="search"
              placeholder="Buscar"
              inputProps={{ "aria-label": "buscar" }}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Tooltip title="Notificaciones">
              <IconButton onClick={() => handleOpenNotifications()}>
                <NotificationsNoneIcon className={classes.ColorWhiteIcons} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Terminos y Condiciones">
              <IconButton onClick={() => handleOpenModalConditions("terms")}>
                <HelpOutlineIcon className={classes.ColorWhiteIcons} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Politicas">
              <IconButton onClick={() => handleOpenModalConditions("politics")}>
                <AssignmentIcon className={classes.ColorWhiteIcons} />
              </IconButton>
            </Tooltip>

            <React.Fragment>
              <Tooltip title="Cuenta">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={tool ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={tool ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {localStorage.getItem("nameOfClient").slice(0, 1)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={tool}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: classes.MenuStyles,
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => setOpenModalAccount(true)}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Mi Cuenta
                </MenuItem>
                {/* <MenuItem>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Salir
                </MenuItem> */}
              </Menu>
            </React.Fragment>
          </Box>
        </Toolbar>
      </AppBar>

      {openModalConditions === true && (
        <ModalConditionsAndTerms
          open={openModalConditions}
          setOpen={setOpenModalContions}
          tittle={tittleConditions}
          description={() => {
            return descriptionConditions ? (
              <TransformText text={descriptionConditions} />
            ) : (
              <div></div>
            );
          }}
        />
      )}

      {openModalNotifications === true && (
        <ModalNotifications
          open={openModalNotifications}
          setOpen={setOpenModalNotifications}
        />
      )}

      {openModalAccount == true && (
        <ModalMyAccount open={openModalAccount} setOpen={setOpenModalAccount} />
      )}
    </React.Fragment>
  );
}

const ModalNotifications = (props) => {
  const classes = stylesHeader();
  const classesBlue = stylesTemplate1ClientHome();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <AppBarModalTemplate1
        title={"Notificaciones"}
        title_accion="Cerrar"
        accion={() => props.setOpen(false)}
      />
      <DialogContent className="scroll">
        <Box className={classes.boxImage}>
          <img src={notificaciones} width="300" alt="Image Notification" />
          <Typography>Sin notificaciones.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classesBlue.BlueColor}
          onClick={() => props.setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ModalConditionsAndTerms = (props) => {
  const classes = stylesTemplate1ClientHome();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <AppBarModalTemplate1
        title={props.tittle}
        title_accion="Cerrar"
        accion={() => props.setOpen(false)}
      />
      <DialogContent className="scroll">{props.description()}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classes.BlueColor}
          onClick={() => props.setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalMyAccount = (props) => {
  const classes = stylesHeader();
  const emialLocalStorage = localStorage.getItem("emailOfClient");
  const nameLocalStorage = localStorage.getItem("nameOfClient");
  const [charging, setCharging] = useState(false);
  const [email, setEmail] = useState(
    emialLocalStorage === "N/A" ? "" : emialLocalStorage
  );
  const [name, setName] = useState(
    nameLocalStorage === "N/A" ? "" : nameLocalStorage
  );

  const onSubmit = async () => {
    const id = localStorage.getItem("idOfClient");
    setCharging(true);
    consulta(
      `api/v1.0/process/client/${id}/update`,
      { email, full_name: name },
      "put",
      (error, estado, resp) => {
        if (estado === 200) {
          Toast.fire({ icon: "success", title: "Datos guardados" });
          localStorage.setItem("emailOfClient", email);
          localStorage.setItem("nameOfClient", name);
          props.setOpen(false);
        } else {
          Toast.fire({
            icon: "info",
            title: "Ingrese datos a guardar.",
          });
        }
        setCharging(false);
      },
      false
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <AppBarModalTemplate1
        title={"Mi Cuenta"}
        title_accion="Cerrar"
        accion={() => props.setOpen(false)}
      />
      <DialogContent className="scroll">
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            className={classes.boxImage}
          >
            <img src={profile} width="200" alt="Imagen" />
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
            <>
              <TextField
                value={email}
                required
                type="email"
                id="email"
                name="email"
                label="Correo"
                fullWidth
                color="secondary"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.boxFormInput}
              />

              <TextField
                value={name}
                required
                type="text"
                id="name"
                name="name"
                label="Nombre"
                color="secondary"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                className={classes.boxFormInput}
              />

              <Button
                variant="contained"
                size="large"
                disabled={charging}
                fullWidth
                className={classes.boxFormButton}
                onClick={() => onSubmit()}
              >
                {charging && (
                  <Box className={classes.loading}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                Guardar
              </Button>
            </>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classes.BlueColor}
          onClick={() => props.setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
