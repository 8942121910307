import React from "react";
import { AppBar, Box, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";

const CrearAccion = (title_accion, accion) => {
  const resp = accion ? (
    <IconButton style={{ color: "white" }} onClick={accion.bind(this, false)}>
      {" "}
      <CloseIcon />{" "}
    </IconButton>
  ) : (
    ""
  );
  return resp;
};

export function AppBarModalTemplate1(props) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography sx={{ flexGrow: "1" }}>
          <Box fontWeight="fontWeightBold" sx={{ fontSize: "25px" }}>
            {props.title}
          </Box>
        </Typography>
        {CrearAccion(props.title_accion, props.accion)}
      </Toolbar>
    </AppBar>
  );
}
