import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { StylesMessages } from "../../global/css/components/StylesTemplate1";
import notFoundImage from "../../global/images/notFound.svg";
import suscribeImage from "../../global/images/suscribe.svg";
import EmptyImage from "../../global/images/Empty.svg";

export const Suscribe = () => {
  const classes = StylesMessages();
  const { id, name } = useParams();

  return (
    <Box className={classes.boxBody}>
      <Box className={classes.boxLogin}>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{ color: "#3498db" }}
        >
          Bienvenido
        </Typography>
        <img src={suscribeImage} width="200" height="200"></img>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: "#3498db", padding: "10px" }}
        >
          Suscríbete ahora y accede a todos los cursos que tenemos para ti.
        </Typography>
        <Button
          sx={{
            margin: "20px",
            backgroundColor: "#3498db",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => window.open(`/${name}/${id}/suscripcion`)}
          variant="contained"
          size="large"
        >
          Suscríbete aquí
        </Button>
      </Box>
    </Box>
  );
};

export const EmptyDataUser = () => {
  const classes = StylesMessages();

  return (
    <Box className={classes.boxBody}>
      <Box className={classes.boxLogin}>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{ color: "#3498db" }}
        >
          Uy!
        </Typography>
        <img src={notFoundImage} width="200" height="200"></img>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: "#3498db", padding: "10px" }}
        >
          Lo sentimos. No logramos traer tu información, intenta más tarde por
          favor.
        </Typography>
      </Box>
    </Box>
  );
};

export const EmptySuscription = () => {
  const classes = StylesMessages();
  return (
    <Box className={classes.boxBody}>
      <Box className={classes.boxLogin}>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{ color: "#3498db" }}
        >
          Uy!
        </Typography>
        <img src={notFoundImage} width="200" height="200"></img>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: "#3498db", padding: "10px" }}
        >
          La suscripción a la cual intentas acceder no existe.
        </Typography>
      </Box>
    </Box>
  );
};

export const EmptyData = ({ message }) => {
  const classes = StylesMessages();
  return (
    <Box className={classes.boxBody} style={{ height: "80vh" }}>
      <Box className={classes.boxLogin}>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{ color: "#3498db" }}
        >
          Oops!
        </Typography>
        <img src={EmptyImage} width="200" height="200"></img>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: "#3498db", padding: "10px" }}
        >
          Sin cursos registrados
        </Typography>
      </Box>
    </Box>
  );
};
