import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import InputFile from "../general/InputFile";
import { BtnForm } from "../general/ButtonAccion";
import { Toast } from "../../global/js/funciones";
import Information from "../general/Information";

class CreateMaterialModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      names: { value: "", error: false },
      description: "",
      archivoGeneric: "",
    };
    this.fileInput = React.createRef();
  }

  onChange = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: { value: value },
    });
  };

  onChangeSimple = ({ target }) => {
    // evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  conError(valor) {
    return valor ? true : false;
  }

  onSubmit = (e) => {
    let { names } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!names.value) {
      names.error = obligatorio;
      error = true;
      this.setState({ names: { error: true } });
    }
    if (!error) {
      this.sendData();
    } else
      Toast.fire({
        title: "Por favor complete todos los campos obligatorios",
        types: "info",
      });
  };
  sendData() {
    let { names, description, archivoGeneric } = this.state;
    let { showModalAddModulesMaterial, processMaterial } = this.props;

    let archivoSend = archivoGeneric
      ? document.getElementById("archivoGeneric").files[0]
      : "";
    const data = new FormData();
    data.append("name", names.value);
    data.append("description", description);
    data.append("files", archivoSend);
    data.append("principal", processMaterial);
    data.append("type_files", 1);
    data.append("weightTotal", 0);
    data.append("weight", 0);

    this.props.saveValueGenericFile(data, () =>
      this.setState({ charging: false })
    );
    this.reset();
    showModalAddModulesMaterial(false);
  }

  reset() {
    this.setState({
      names: { value: "", error: false },
      description: "",
      archivoGeneric: "",
    });
  }

  view() {
    let { names, description, charging, archivoGeneric } = this.state;
    return !charging ? (
      <Grid container spacing={3} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <TextField
            value={names.value}
            required
            type="text"
            id="names"
            name="names"
            label="Nombre"
            error={this.conError(names.error)}
            helperText={names.error}
            fullWidth
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={description}
            type="text"
            id="description"
            name="description"
            label="Descripción"
            fullWidth
            onChange={this.onChangeSimple}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="oculto"
            ref={this.fileInput}
            type="file"
            id="archivoGeneric"
            name="archivoGeneric"
            label="Archivo"
            fullWidth
            autoComplete="billing address-line1"
            onChange={this.onChangeSimple}
          />
          <Grid item xs={12}>
            <InputFile
              label="Archivo"
              id="archivoGeneric"
              value={archivoGeneric}
            />
          </Grid>
        </Grid>
        <Information
          title="Importante"
          description="Se recomienda que las imágenes cargadas tengan un ancho y largo  entre los 800px y los 1500px."
          type="info"
        />
      </Grid>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let { showModalAddModulesMaterial, modalAddModulesMaterial } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalAddModulesMaterial}
          onClose={() => showModalAddModulesMaterial(false)}
        >
          <AppBarModal
            title={" ¡ Agregar Material de Apoyo ! "}
            showMessageModal={() => showModalAddModulesMaterial(false)}
            title_accion="Cerrar"
            accion={() => showModalAddModulesMaterial(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              text="CANCELAR"
              variant="outlined"
              callback={() => showModalAddModulesMaterial(false)}
            />
            <BtnForm text="GUARDAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
CreateMaterialModules.propTypes = {
  modalAddModulesMaterial: PropTypes.bool.isRequired,
};

export default CreateMaterialModules;
