import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBarModal from "../general/AppBarModal";
import ListarDatos from "../general/ListarDatos";
import { BtnDelete } from "../general/ButtonAccion";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  Fab,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { consulta, viewError, formulario, Toast, confirmAction } from "../../global/js/funciones";
import ExamAnswerAdd from "./ExamAnswerAdd";
import moment from "moment";
import { ANSWER } from "../general/helperGeneric";


class ExamAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: false,
      answer: [],
      checked: [],
      infoChecked: [],
    };
  }

  async componentDidUpdate({
    modalListExamAnswer,
    modalAddExamAnswer,
    infoChecked,
  }) {
    let { modalListExamAnswer: modalListExamAnswerNew } = this.props;
    if (modalListExamAnswerNew !== modalListExamAnswer) {
      this.data();
    }
    let { modalAddExamAnswer: modalAddExamAnswerNew } = this.props;
    if (modalAddExamAnswerNew !== modalAddExamAnswer) {
      this.data();
    }
    let { infoChecked: infoCheckedNew } = this.state;
    if (infoCheckedNew !== infoChecked) {
      this.saveSelectAnswer();
    }
  }

  async data() {
    this.setState({ charging: true, checked: [] });
    let answer = await this.listAnswer();
    this.setState({ answer, charging: false });
    this.handCheck();
  }

  async listAnswer() {
    let { process } = this.props;
    let data = process.principal.id;
    let f = ANSWER; //Aqui debe ir el helper
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/list/${data}/permission`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

   deleteAnswer = async (info) => {
    let f = info.principal.id;
    let data = info.secondary;
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/values/${f}/delete/${data}/permission`,
        {},
        "put",
        (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.",
            types = "error";
          if (!error) {
            if (estado === 200) {
              types = "success";
              title = resp.title;
              this.data();
            } else {
              types = "info";
              title = resp.title ? resp.title : viewError(resp);
            }
          }
          resolve(estado === 200 && !error ? resp : null);
          Toast.fire({ icon: types, title });
        }
      );
    });
  }

  addPermissionGeneric = async (data) => {
    let { process, showModalAddExamAnswer } = this.props;
    data.append("secondary", process.principal.id);
    formulario(
      `api/v1.0/values/permission/add/exam`,
      data,
      "post",
      (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.",
          types = "error";
        if (!error) {
          if (estado === 200) {
            types = "success";
            title = resp.title;
            showModalAddExamAnswer(false);
            this.data();
          } else {
            types = "info";
            title = resp.title ? resp.title : viewError(resp);
          }
        }
        Toast.fire({ icon: types, title });
      }
    );
  };

  addGenericAnswer = async (data) => {
    consulta(
      `api/v1.0/values/answer/exam`,
      data,
      "post",
      (error, estado, resp) => {
        let title = "Ha ocurrido un error, contacte con el administrador.",
          types = "error";
        if (!error) {
          if (estado === 200) {
            types = "success";
            title = resp.title;
            //this.data()
          } else {
            types = "info";
            title = resp.title ? resp.title : viewError(resp);
          }
        }
      }
    );
  };


  saveSelectAnswer() {
    let { checked } = this.state;
    let { process } = this.props;
    let data = [];
    let info = [];
    checked.map((dataIn) => data.push({ view: dataIn }));
    if (process) {
      info = { data, secondary: process.principal.id };
      this.addGenericAnswer(info);
    }
  }

  handCheck() {
    let { answer, checked } = this.state;
    let newChecked = [...checked];
    answer.map((data) => {
      if (data.principal.type_data === "1") {
        newChecked.push(data.id);
      }
    });
    this.setState({ checked: newChecked });
  }

  view() {
    let { charging, answer, checked } = this.state;
    let { process } = this.props;
    const handleChange = (data) => async () => {
      let newChecked = [...checked];
      const currentIndex = checked.indexOf(data.id);
      if (currentIndex === -1) {
        newChecked = [data.id];
      } else {
        newChecked.splice(currentIndex, 1);
      }
      this.setState({ checked: newChecked, infoChecked: newChecked });
    };

    const handleChangeMultiple = (data) => async () => {
      let newChecked = [...checked];
      const currentIndex = checked.indexOf(data.id);
      if (answer.length - 1 > newChecked.length) {
        //condicion para que no se puedan seleccionar todas las respuestas tiene que existir una incorrecta
        if (currentIndex === -1) {
          newChecked.push(data.id);
        } else {
          newChecked.splice(currentIndex, 1);
        }
      }
      if (currentIndex !== -1) {
        //sino entra a la anterior condicion se pueda deseleccionar
        newChecked.splice(currentIndex, 1);
      }
      this.setState({ checked: newChecked, infoChecked: newChecked });
    };
    const actions = (rowData) => {
      const onClickDelete = (data) => {
        confirmAction(() => this.deleteAnswer(data));
      };
      const detail = process.principal.type_data === "2" && (
        <Checkbox
          edge="end"
          checked={checked.indexOf(rowData.id) !== -1}
          onChange={handleChange(rowData)}
        />
      );
      const detailMultiple = process.principal.type_data === "3" && (
        <Checkbox
          edge="end"
          checked={checked.indexOf(rowData.id) !== -1}
          onChange={handleChangeMultiple(rowData)}
        />
      );

      let delet = <BtnDelete callback={() => onClickDelete(rowData)} />;

      return (
        <div>
          {delet}
          {detail}
          {detailMultiple}
        </div>
      );
    };

    return !charging ? (
      <>
        <ListarDatos
          id="tbl_list_generic_categories"
          datos={answer}
          acciones={(row) => actions(row)}
          fila_principal={({ principal: { name } }) => name.toUpperCase()}
          filas={[
            {
              id: "date_add",
              enLista: true,
              show: ({ principal: { date_add } }) =>
                moment(date_add).format("YYYY-MM-DD, h:mm a"),
            },
          ]}
        />
      </>
    ) : (
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item md={2}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  render() {
    let {
      showModalListExamAnswer,
      modalListExamAnswer,
      showModalAddExamAnswer,
      modalAddExamAnswer,
    } = this.props;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={modalListExamAnswer}
          onClose={() => showModalListExamAnswer(false)}
        >
          <AppBarModal
            title={"¡ Respuestas ! "}
            showMessageModal={() => showModalListExamAnswer(false)}
            title_accion="Cerrar"
            accion={() => showModalListExamAnswer(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              <Grid item xs={12} md={12}>
                {this.view()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              style={{ maxWidth: "100%" }}
            >
              <Fab
                style={{ zIndex: 1, position: "relative" }}
                color="primary"
                aria-label="add"
                onClick={() => showModalAddExamAnswer(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
        <ExamAnswerAdd
          showModalAddExamAnswer={showModalAddExamAnswer}
          modalAddExamAnswer={modalAddExamAnswer}
          addPermissionGeneric={this.addPermissionGeneric}
        />
      </>
    );
  }
}
ExamAnswer.propTypes = {
  modalListExamAnswer: PropTypes.bool.isRequired,
  modalAddExamAnswer: PropTypes.bool.isRequired,
};

export default ExamAnswer;
