import React from "react";
import { Box, Typography } from "@mui/material";
import { StylesNotFound } from "../../../global/css/components/StylesApp";
import notFoundImage from "../../../global/images/notFound.svg";

export default function NotFound() {
  const classes = StylesNotFound();
  return (
    <Box className={classes.boxBody}>
      <Box className={classes.boxLogin}>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{ color: "#3498db" }}
        >
          Uy!
        </Typography>
        <img src={notFoundImage} width="200" height="200"></img>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: "#3498db", padding: "10px" }}
        >
          La pagina a la cual intentas acceder no existe.
        </Typography>
      </Box>
    </Box>
  );
}
